import React, { useEffect } from 'react'
import Styled from "styled-components";
import Banner from '../commonComponets/Banner';
import SignUpForm from '../componets/signup/SignUpForm';


const SignUp: React.FC = (props) => {


    useEffect(()=>{
        document.title = "Sign up | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <div style={{ background: "rgb(244, 244, 244)", }} >
            <Banner title="Sign Up" />
            <SignUpForm />
        </div>
    )
}

export default SignUp

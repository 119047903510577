import React from 'react'
import Example from "../../assets/images/container-ship-596083_640.jpg"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from '../../store/root.reducer';
import { useHistory } from "react-router-dom"
export const OurTeams: React.FC = () => {

    const dispatch = useDispatch();

    const history = useHistory()


    const OurTeam = useSelector((state: RootState) => state.Content.OurTeam)

    return (
        <div className="container pt-5  ">
            <h1 className="text-center pt-4 mb-4" style={{ fontWeight: "bold" }} >
                Our Teams
            </h1>
            <div className="row mt-3 mb-5" >
                {
                    OurTeam.map((value, index) => {
                        return (
                            <div onClick={()=>history.push(`/Careers/${value.LargeHeading.replace(/\ /g,"-").replace(/\//g,"_")}`)} key={value._id} className="col-md-4 col-sm-12 mt-4" >
                                <div className="card teams-card-hover text-center" style={{ border: "0" }} >
                                    <img style={{ height: "200px" }} className="card-img-top" src={value.Image_path} alt="Card image cap" />
                                    <div className="card-body" >
                                        <h4 style={{ fontWeight: "bold" }} className="card-title"> {value.LargeHeading} </h4>
                                        <p className="card-text">
                                            {value.SmallHeading} positions
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

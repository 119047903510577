import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser';

const BlogDetailsScreen: React.FC<{ Text: string }> = props => {
    return (
        <div className="container pt-5 pb-5 mb-5">
            {/* <hr className="pb-4" /> */}
            {parse(props?.Text || "")}
        </div>
    )
}

BlogDetailsScreen.propTypes = {

}

export default BlogDetailsScreen

import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import Styled from "styled-components"
import { baseOrange, basePurple, white, AndroidApp } from '../../constants'
import { useRealTimeDeviceDetector } from "../../helpers/useRealTimeDeviceDetector"
import logo from "../../assets/images/newLogo1.jpg"
import mailIcon from "../../assets/icons/mail.svg"
import homeIcon from "../../assets/icons/home.svg"
import phoneIcon from "../../assets/icons/phone.svg"
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import appleStoreIcon from "../../assets/icons/apple.svg";
import playStoreIcon from "../../assets/icons/google.svg";
import { useSelector } from 'react-redux'
import { RootState } from '../../store/root.reducer'
import parse from 'html-react-parser';


const Footer: React.FC = (props) => {

    const location = useLocation()
    const { IsMob } = useRealTimeDeviceDetector()

    // FooterStaticContent
    // FooterSectionHeading
    const FooterStaticContent = useSelector((state: RootState) => state.Content.FooterStaticContent)
    const FooterSectionHeading = useSelector((state: RootState) => state.Content.FooterSectionHeading)
    if (location.pathname.split("/").includes("admin")) {
        return <></>
    }

    return (
        <footer style={{ backgroundColor: FooterStaticContent?.BackgroundColor, padding: "0 10%", color: FooterStaticContent?.FontColor, paddingBottom: "60px", }} className="" >
            <Link to="/">
                <img className="mt-5" style={{ cursor: "pointer", height: "50px" }} src={FooterStaticContent?.Logo} alt="Logo" />
            </Link>
            <div className="row mt-5" >
                {
                    FooterSectionHeading?.map((value, index) => {
                        return (
                            <div key={value._id} className="col-lg-3 col-md-6 " >
                                <h5>
                                    {value.Heading}
                                </h5>
                                <ul style={{ listStyle: "none" }}>
                                    {
                                        value.Children.map((value2, index2) => {
                                            return (
                                                <li key={value2._id} className="mb-1" ><Link style={{color: FooterStaticContent?.FontColor,}} to={value2.Link} className="" >{value2.SubHeading}</Link></li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        )
                    })
                }

            </div>
            <div className="row mt-4" >
                <div className="col-lg-8 col-md-12" >
                    <ul style={{ display: "flex", listStyle: "none" }} >
                        <li className="m-2" ><a style={{ color: white }} target="blank" href={FooterStaticContent?.FacebookLink || "/"} ><FacebookIcon  style={{color:FooterStaticContent?.FontColor}}/></a></li>
                        <li className="m-2" ><a style={{ color: white }} target="blank" href={FooterStaticContent?.InstagramLink || "/"} ><InstagramIcon style={{color:FooterStaticContent?.FontColor}} /></a></li>
                        <li className="m-2" ><a style={{ color: white }} target="blank" href={FooterStaticContent?.TwitterLink || "/"} > <TwitterIcon style={{color:FooterStaticContent?.FontColor}} /></a></li>
                    </ul>
                </div>
                <div className="col-lg-4 col-md-12" >
                    <a target="blank" href={FooterStaticContent?.AndroidAppLink || "/"}><img style={{ height: "40px", margin: "10px", cursor: "pointer" }} src={playStoreIcon} /></a>
                    <a target="blank" href={FooterStaticContent?.IOSAppLink || "/"} ><img style={{ height: "40px", margin: "10px", cursor: "pointer" }} src={appleStoreIcon} /></a>
                </div>
            </div>
            <div className="row mt-4" >
                <div className="col-lg-8" style={{ width: "95%" }} >
                    {FooterStaticContent?.CopyrightText}
                </div>
                <div className="col-lg-4" style={{ marginTop: IsMob ? "10px" : "" }}>
                    <span  style={{color: FooterStaticContent?.FontColor,}}>
                        {parse(FooterStaticContent?.TermsAndConditionsLinkPara.replace(/\<a/g,`<a style="color:${FooterStaticContent.FontColor}" `) || "")}
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer

import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import DriveWithMeScreen from '../componets/driveWithMe/DriveWithMeScreen'

const DriveWithMe = () => {

    useEffect(()=>{
        document.title = "Drive with me | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <>
        <Banner title="Drive With me" />
        <DriveWithMeScreen/>
        </>
    )
}

export default DriveWithMe

import React, { useEffect, useState } from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { CustomButton } from '../../commonComponets';
import { baseOrange, basePurple } from '../../constants';
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../store/actions"
import { RootState } from '../../store/root.reducer'

const ContactUsScreen: React.FC = (props) => {


    const dispatch = useDispatch();

    const [Name, setName] = useState("")
    const [Email, setEmail] = useState("")
    const [Subject, setSubject] = useState("")
    const [PhoneNo, setPhoneNo] = useState("")
    const [Desc, setDesc] = useState("")


    const ContactUs = useSelector((state: RootState) => state.Content.ContactUs)

    useEffect(() => {
        dispatch(Actions.GetContactUs())
    }, [])




    return (
        <>
            <div className="Contact_Us mt-5 mb-5">
                <div className="container mt-5">
                    <div className="row mt-5">
                        <div className="col-lg-12 col-sm-12">
                            <h3 style={{ textAlign: "center" }}>Contact Us</h3>
                            <hr />

                            <div className="row mt-5">
                                <div className="col-lg-6 col-md-12 mt-2">
                                    <div className="form-group">
                                        <input
                                            style={{ height: "54px" }}
                                            type="text" placeholder="Name *"
                                            required
                                            className="form-control"
                                            value={Name}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 mt-2">
                                    <div className="form-group">
                                        <input
                                            style={{ height: "54px" }}
                                            type="email" placeholder="Email *"
                                            required
                                            className="form-control"
                                            value={Email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-lg-12 mt-2">
                                    <input
                                        style={{ height: "54px" }}
                                        type="number"
                                        placeholder="Phone No *"
                                        required
                                        className="form-control"
                                        value={PhoneNo}
                                        onChange={e => setPhoneNo(e.target.value)}
                                        maxLength={11}
                                    />
                                </div>
                                <div className="form-group col-lg-12 mt-2">
                                    <input
                                        style={{ height: "54px" }}
                                        type="text" placeholder="Subject *"
                                        required
                                        className="form-control"
                                        value={Subject}
                                        onChange={e => setSubject(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-lg-12 mt-2">
                                    <textarea
                                        rows={10} cols={50}
                                        placeholder="Message *"
                                        required
                                        className="form-control"
                                        value={Desc}
                                        onChange={e => setDesc(e.target.value)}
                                    />
                                </div>
                                <CustomButton
                                    varient="solid"
                                    className="mt-3 ml-1 mb-5 "
                                    backgorundColor={basePurple}
                                    width="98%"
                                    height="50px"
                                    hoverbackgroundColor={baseOrange}
                                    styles={{ fontSize: "18px", fontWeight: "bold" }}
                                    noFilter
                                    onClick={async () => {
                                        const res: any = await dispatch(Actions.Contactus(Name, Email, Subject, Desc,PhoneNo))
                                        if (res) {
                                            setName("")
                                            setEmail("")
                                            setSubject("")
                                            setDesc("")
                                            setPhoneNo("")
                                        }
                                    }}
                                >
                                    SUBMIT
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUsScreen

import React, { useEffect, useMemo, useState } from 'react'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { CustomButton } from '../../../commonComponets';
import { baseOrange, basePurple, Endpoint, END_POINT_IP } from '../../../constants';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom"
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useDispatch, useSelector } from "react-redux"

import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { RootState } from '../../../store/root.reducer';
import * as Actions from "../../../store/actions"
import { BookingObjINterface, DriverInfo } from '../../../interfaces';
import { CircularProgress } from '@material-ui/core';
import MapWithDirections from '../../../commonComponets/MapWithDirections';
import { io } from "socket.io-client";
import axios from 'axios';

const socket = io(Endpoint)


const SelectRider: React.FC = () => {
    const { id } = useParams<{ id: any }>()
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()

    const [DriverLocation, setDriverLocation] = useState<any>(null)
    const [DriverInfo, setDriverInfo] = useState<DriverInfo | null>(null)
    const [IsLoading, setIsLoading] = useState(false)
    const [Data, setData] = useState<BookingObjINterface | null>(null)
    const [Step, setStep] = useState<number>(1)
    const [Selected, setSelected] = useState<"Sedan" | "SUV" | "Truck" | "">("")

    const onGoingBooking = useSelector((state: RootState) => state.Booking.OnGoingBookning)
    const Token = useSelector((state: RootState) => state.User.Token)


    useEffect(() => {
        if (Data?.PickedUpByID && !DriverInfo) {
            getDriverInfo()
        }
    }, [Data])


    useEffect(() => {
        getBooking()
    }, [])


    useEffect(() => {
        if (Data?.Status !== "Processing" && Data?.PickedUpByID) {
            console.log("SignalLocked", Data?.PickedUpByID)
            socket.on(Data?.PickedUpByID, item => {
                console.log("signal came", item)
                setDriverLocation(prex => ({
                    "latitude": item.latitude,
                    "latitudeDelta": 0.0922,
                    "longitude": item.longitude,
                    "longitudeDelta": 0.0421
                }))
                // dispatch(Actions.getOnGoingBooking())
            })
        }
    }, [Data])


    useEffect(() => {
        setTimeout(() => {
            socket.on('bookings', item => {
                console.log("signal came")
                getBooking()
            })
        }, 2000)
    }, [])

    async function getBooking() {
        setIsLoading(true)
        const res: any = await dispatch(Actions.GetOneBookings(id));
        setIsLoading(false)

        if (res.Error) return setData(null);
        const booking: BookingObjINterface = res.Bookings[0]
        setData(booking)

    }


    async function getDriverInfo() {
        try {
            const res = await axios.get<any>(`${Endpoint}/api/v1/Driver/get?Page=1&Size=1&DriverID=${Data?.PickedUpByID}`, {
                headers: {
                    "x-auth-token": Token
                }
            })
            setDriverInfo(res.data.Drivers[0])
        } catch (error) {
            console.log(error)
        }
    }

    const MyMapComp = useMemo(() => {
        return <MapWithDirections
            origin={{ lat: Data?.SendersLat && +Data?.SendersLat || 0, long: Data?.SendersLong && +Data?.SendersLong || 0 }}
            destination={{ lat: Data?.RecieversLat && +Data?.RecieversLat || 0, long: Data?.RecieversLong && +Data?.RecieversLong || 0 }}
            containerStyle={{ height: "700px", width: "100%", marginTop: "50px" }}
            DriverLocation={DriverLocation}
            DriverType={Data?.VehicleType}
            SendersLat={Data?.SendersLat}
            SendersLong={Data?.SendersLong}
            RecieversArray={Data?.ReceiversArray || []}
            UseLessState69={0}
        />
    }, [DriverLocation, Data?.SendersLat, Data?.SendersLong, Data?.ReceiversArray])

    // if(location.pathname.split("/").includes("admin") && !onGoingBooking) return <Redirect to="/admin/user" />
    // if(!onGoingBooking) return <Redirect to="/" />
    if (IsLoading) return (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress style={{ margin: "50px" }} />
        </div>
    )
    if (!Data) return (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h1 style={{ color: "grey", margin: "50px" }}>No Booking found </h1>
        </div>
    )





    return (
        <section className={`placeOrder ${location.pathname.split("/").includes("admin") ? "" : "container"}`} style={{ padding: "10px 0 20px 0" }}>
            <div className="leftSection">
                <h3>
                    {
                        Data.Status == "Processing" ? "Connecting to a Driver..." : null
                    }
                    {
                        Data.Status == "In Progress" ? "Rider is going to pick up location.." : null
                    }
                    {
                        Data.Status == "Picked up" ? "Rider is going to drop off location.." : null
                    }
                    {
                        Data.Status == "Delivered" ? "Delivered " : null
                    }


                </h3>
                {
                    DriverInfo ? (
                        <>
                            <div style={{ height: "auto" }} className="orderInside mb-2">
                                <h3 className="mt-3" style={{ marginLeft: "28px" }}>
                                    Driver Info
                                </h3>
                                <span className=" mb-2 mt-2" >
                                    <img
                                        style={{ height: "100px", marginLeft: "30px" }}
                                        src={DriverInfo.ProfilePicture}
                                    />
                                </span>

                                <span className="Toggler " >
                                    Name:  <span style={{ color: basePurple, marginLeft: "10px", textTransform: "capitalize" }}> {DriverInfo.Name}</span>
                                </span>
                                <span className="Toggler " >
                                    Phone No:  <span style={{ color: basePurple, marginLeft: "10px", textTransform: "capitalize" }}> {DriverInfo.ContactNumber}</span>
                                </span>
                                <span className="Toggler " >
                                    Vehicle Plate Number:  <span style={{ color: basePurple, marginLeft: "10px", }}> {DriverInfo.VehiclePlatenumber}</span>

                                </span>

                            </div>
                        </>
                    ) : null
                }
                <>
                    <div style={{ height: "auto" }} className="orderInside mb-2">
                        <h3 className="mt-3" style={{ marginLeft: "28px" }}>
                            Bookings Summary
                        </h3>
                        <span className="Toggler " >
                            Total Fee:  <span style={{ color: basePurple, marginLeft: "10px" }}> ${Data.TotalFee}</span>
                        </span>
                        <span className="Toggler " >
                            Driver Tip:  <span style={{ color: basePurple, marginLeft: "10px" }}> ${Data.DriverTip}</span>
                        </span>
                        <span className="Toggler " >
                            Total Distance:  <span style={{ color: basePurple, marginLeft: "10px" }}> {Data.TotalDistance}KM</span>
                        </span>
                        <span className="Toggler " >
                            Status:  <span style={{ color: basePurple, marginLeft: "10px" }}> {Data.Status}</span>
                        </span>
                        <span className="Toggler " >
                            Sender's Name: <span style={{ color: basePurple, marginLeft: "10px" }}> {Data.SendersName}</span>
                        </span>
                        {
                            Data.ReceiversArray.map((value: any, index) => {
                                return (
                                    <span key={index} className="Toggler " >
                                        Reciever #{index + 1}'s Name: <span style={{ color: basePurple, marginLeft: "10px" }}> {value.RecieversName}</span>
                                    </span>
                                )
                            })
                        }
                        {
                            Data.SendersEmail ? (
                                <span className="Toggler " >
                                    Sender's Email: <span style={{ color: basePurple, marginLeft: "10px" }}> {Data.SendersEmail}</span>
                                </span>
                            ) : null
                        }
                        {
                            Data.ReceiversArray.map((value: any, index) => {
                                if (value.RecieversEmail) return (
                                    <span key={index} className="Toggler " >
                                        Reciever #{index + 1}'s Email: <span style={{ color: basePurple, marginLeft: "10px" }}> {value.RecieversEmail}</span>
                                    </span>
                                )
                                return null
                            })
                        }
                        <span className="Toggler " >
                            Sender's PhoneNo:  <span style={{ color: basePurple, marginLeft: "10px" }}> {Data.SendersPhoneNo}</span>
                        </span>
                        {
                            Data.ReceiversArray.map((value: any, index) => {
                                return (
                                    <span key={index} className="Toggler " >
                                        Reciever #{index + 1}'s PhoneNo: <span style={{ color: basePurple, marginLeft: "10px" }}> {value.RecieversPhoneNo}</span>
                                    </span>
                                )
                                return null
                            })
                        }
                        <span className="Toggler " >
                            Sender's Address:  <span style={{ color: basePurple, marginLeft: "10px" }}> {Data.SendersAddress}</span>
                        </span>
                        {
                            Data.ReceiversArray.map((value: any, index) => {
                                return (
                                    <span key={index} className="Toggler " >
                                        Reciever #{index + 1}'s Address: <span style={{ color: basePurple, marginLeft: "10px" }}> {value.RecieversAddress}</span>
                                    </span>
                                )
                                return null
                            })
                        }

                    </div>
                </>





            </div>
            <div className="rightSection">
                {MyMapComp}
            </div>
        </section>
    )
}

export default SelectRider

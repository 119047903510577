import React, { useEffect } from 'react'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../../store/actions"
import { RootState } from '../../store/root.reducer';
import parse from 'html-react-parser';
import ServiceCard from '../../commonComponets/ServiceCard';
import { useHistory } from "react-router-dom"


const DriveWithMeScreen = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const Policy = useSelector((state: RootState) => state.Content.Policy)
    const DriveWithMe = useSelector((state: RootState) => state.Content.DriveWithMe)


    useEffect(() => {
        dispatch(Actions.GetPolicy("DriveWithUs"))
    }, [])


    return (
        <>
            {/* <div className="container pt-5 pb-5 ">
                <h2 className="text-center pt-4 mb-5" >
                    <ArrowRightIcon /> {Policy.Heading}
                </h2>

                <hr className="pb-4" />
                {parse(Policy?.Text || "")}



            </div> */}
            {
                DriveWithMe.map((value, index) => {
                    return <ServiceCard index={index} Data={{
                        Desc: value.Desc,
                        ImageALT: value.LargeHeading,
                        Img: value.Image_path,
                        LargeHeading: value.LargeHeading,
                        SmallHeading: value.SmallHeading,
                        ActionButtonAction: index == 0 ?  () => history.push("/becomeDriver") : () => history.push("/Logistics"),
                        ActionButtonText: index == 0 ?  "Driver Sign Up" : "Request more logistics",
                    }} key={index} />
                })
            }
        </>
    )
}

export default DriveWithMeScreen

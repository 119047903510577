import React, { useEffect, useState, useMemo } from 'react'
import { MapWithDirectionsInterface } from "../interfaces"
import { GoogleMap, Marker, withGoogleMap, DirectionsRenderer } from "react-google-maps"


const MapWithDirections: React.FC<MapWithDirectionsInterface> = (props) => {

    const [Directions, setDirections] = React.useState<any>([])
    console.log(Directions)
    useEffect(() => {
        myDirections()
    }, [props.SendersLat, props.SendersLong, props.RecieversArray, props.UseLessState69])

    async function myDirections() {
        // setDirections([])
        let newDir: any = []
        for (let index = 0; index < props.RecieversArray.length; index++) {
            const element = props.RecieversArray[index];



            if (!props.SendersLat && !props.SendersLong && !element.RecieversLat && !element.RecieversLong) return

            const directionsService = new window.google.maps.DirectionsService();

            const origin = { lat: +(props.SendersLat || 0), lng: +(props.SendersLong || 0) };
            const destination = { lat: +(element.RecieversLat || 0), lng: +(element.RecieversLong || 0) };

            const DSR = await directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                }
            );
            newDir = [...newDir, DSR]
        }

        setDirections(newDir)
    }

    const MyMap = withGoogleMap(() => <GoogleMap
        defaultZoom={4}
        defaultCenter={{
            lat: 51.213890,
            lng: -102.462776
        }}
    >
        {/* {
            Directions ? <DirectionsRenderer
                directions={Directions}
            /> : null
        } */}

        {
            Directions.map((value: any) => {
                return (
                    <DirectionsRenderer
                        directions={value}
                    />
                )
            })
        }
        {
            props.SendersLat && props.SendersLong ? (
                <Marker position={{ lat: +props.SendersLat || 0, lng: +props.SendersLong || 0 }} />
            ) : null
        }
        {
            props.RecieversArray.map((value: any, indx: number) => {
                if (value.RecieversLat && value.RecieversLong) {
                    return (
                        <Marker position={{ lat: +value.RecieversLat || 0, lng: +value.RecieversLong || 0 }} />

                    )
                }
                return null

            })
        }
        {
            props.DriverLocation ? (
                <Marker
                    icon={{

                        url: props.DriverType === "SUV" ? '/DriverPointerSUV.png' : props.DriverType === "Sedan" ? '/DriverPointerSedan.png' : props.DriverType === "Van" ? '/DriverPointerVan.png' : '/DriverPointer.png',

                        anchor: new google.maps.Point(17, 46),

                        scaledSize: new google.maps.Size(37, 37)

                    }}
                    // icon={"../assets/icons/DriverPointer.png"}
                    position={{ lat: props.DriverLocation?.latitude || 0, lng: props.DriverLocation?.longitude || 0 }}
                />

            ) : null
        }
    </GoogleMap>)
    const MyNewMapComp = useMemo(() => {
        return <MyMap
            containerElement={<div style={props.containerStyle ? props.containerStyle : {}} />}
            mapElement={<div style={{ height: `100%` }} />}

        />
    }, [props.SendersLat, props.SendersLong, Directions, props.UseLessState69, props.RecieversArray,])
    return (
        <>
            {
                MyNewMapComp
            }
        </>
    )
}

export default MapWithDirections
//use this componet after memoization

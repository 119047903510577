import React, { useEffect } from 'react'
import ServiceCard from '../../commonComponets/ServiceCard'
import ServiceImg_1 from "../../assets/images/ser2-1.jpg"
import ServiceImg_2 from "../../assets/images/ser2-2.jpg"
import ServiceImg_3 from "../../assets/images/ser2-3.jpg"
import ServiceImg_4 from "../../assets/images/ser2-4.jpg"
import ServiceImg_5 from "../../assets/images/ser2-5.jpg"
import ServiceImg_6 from "../../assets/images/ser2-6.jpg"
import { ServiceCardData } from '../../interfaces'
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../store/root.reducer"
import * as Actions from "../../store/actions"
import ReadyToRide from "../home/ReadyToRide"
const LogisticsScreen: React.FC = (props) => {

    const dispatch = useDispatch()

    const TempDev = useSelector((state: RootState) => state.Content.TempDev)

    useEffect(() => {
        // dispatch(Actions.GetLogistics())
    }, [])
    return (
        <>
            {
                TempDev.map((value, index) => {
                    if (index == 0) return <ServiceCard
                        isLogistics={true}
                        index={index}
                        Data={{
                            Desc: value.Desc,
                            ImageALT: value.LargeHeading,
                            Img: value.Image_path,
                            LargeHeading: value.LargeHeading,
                            SmallHeading: value.SmallHeading,
                        }} key={index} />
                })
            }
            <ReadyToRide IsTemp={true} />
            {
                TempDev.map((value, index) => {
                    if (index != 0) return <ServiceCard
                        isLogistics={true}
                        index={index}
                        Data={{
                            Desc: value.Desc,
                            ImageALT: value.LargeHeading,
                            Img: value.Image_path,
                            LargeHeading: value.LargeHeading,
                            SmallHeading: value.SmallHeading,
                        }} key={index} />
                })
            }
        </>
    )
}

export default LogisticsScreen

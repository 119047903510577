import React, { useState } from 'react'
import Styled from "styled-components";
import { CustomButton } from '../../commonComponets'
import { baseOrange, basePurple, white } from '../../constants'
import { Link, Redirect } from "react-router-dom"
import { useHistory } from "react-router-dom"
import * as EmailValidator from 'email-validator';
import { CircularProgress, TextField } from '@material-ui/core';
import * as Actions from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/root.reducer";

const StyledAnchor = Styled.a`
    color:${basePurple};

    &:hover{
        color:${baseOrange};
        cursor:pointer;
    }
`

const ContinueWithKenanForm: React.FC = (props) => {


    const history = useHistory()
    const dispatch = useDispatch()


    const [Email, setEmail] = useState<string>("");
    const [PhoneNo, setPhoneNo] = useState<string>("");
    const [VerificationCode, setVerificationCode] = useState<string>("");
    const [Password, setPassword] = useState<string>("");
    const [ErrorMsg, setErrorMsg] = useState<string>("");
    const [IsError, setIsError] = useState<boolean>(false);
    const [IsLoading, setIsLoading] = useState<boolean>(false);
    const [Step, setStep] = useState<1 | 2>(1);

    const IsLogin = useSelector((state: RootState) => state.User.IsLogin)
    const UserData = useSelector((state: RootState) => state.User.UserData)

    async function submitHandler(): Promise<void> {
        setIsError(false)
        setErrorMsg("")
        if (IsLoading) return;
        try {
            if (!PhoneNo) throw "Please fill all the required fields"
            if (PhoneNo.length < 10 || PhoneNo.length > 11) throw "Please enter a valid phone no!"


            setIsLoading(true)
            const res = await dispatch(Actions.ContinueWithPhoneNo(PhoneNo))

            if (res) throw res

            setStep(2)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsError(true);
            setErrorMsg(typeof error == "string" ? error : "Something went wrong!")
            setIsLoading(false)

        }
        // history.push("/admin/user")
    }

    async function submitHandlerVerificationCode(): Promise<void> {
        setIsError(false)
        setErrorMsg("")
        if (IsLoading) return;
        try {
            if (!VerificationCode) throw "Please fill all the required fields"
            if (VerificationCode.length !== 6) throw "Incorrect verification code"


            setIsLoading(true)
            const res = await dispatch(Actions.ContinueWithVerificationCode(PhoneNo, VerificationCode))

            if (res) throw res

            setStep(2)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsError(true);
            setErrorMsg(typeof error == "string" ? error : "Something went wrong!")
            setIsLoading(false)

        }
        // history.push("/admin/user")
    }


    if (IsLogin && UserData?.Address) return <Redirect to="/admin/user" />
    if (IsLogin && !UserData?.Address) return <Redirect to="/admin/myProfile" />
    return (
        <>
            <div className="container login-form">
                <div className="row display-flex justify-content-center ">
                    {
                        Step === 1 ? (
                            <>
                                <div className="col-lg-8 text-center ">
                                    <div className="form-group mt-5 ">
                                        <TextField
                                            variant="outlined"
                                            style={{ height: "54px" }}
                                            type="number"
                                            label="Enter Phone No"
                                            required
                                            className={`form-control`}
                                            error={IsError && PhoneNo.length < 10 || IsError && PhoneNo.length > 11}
                                            value={PhoneNo}
                                            onChange={(e) => setPhoneNo(e.target.value)}
                                        />
                                    </div>

                                    <p className="text-danger  pt-2">{ErrorMsg}</p>
                                    {
                                        IsLoading ? <CircularProgress /> : (
                                            <CustomButton
                                                varient="solid"
                                                className="mt-4"
                                                backgorundColor={basePurple}
                                                width="100%"
                                                height="50px"
                                                hoverbackgroundColor={baseOrange}
                                                styles={{ fontSize: "18px", fontWeight: "bold" }}
                                                noFilter
                                                onClick={() => submitHandler()}
                                            >
                                                CONTINUE
                                            </CustomButton>
                                        )
                                    }
                                    <p className="mt-4">
                                        Want to use email & password? &nbsp;
                                        <StyledAnchor onClick={() => history.push("/login")}>Login</StyledAnchor> &nbsp; &nbsp;

                                    </p>
                                </div>
                            </>
                        ) : null
                    }

                    {
                        Step === 2 ? (
                            <>
                                <div className="col-lg-8 text-center ">
                                    <h4 className="mt-5">Verification code has been sent to your phone number</h4>
                                    <div className="form-group mt-5 ">
                                        <TextField
                                            variant="outlined"
                                            style={{ height: "54px" }}
                                            type="number"
                                            label="Enter Verification Code"
                                            required
                                            className={`form-control`}
                                            error={IsError && VerificationCode.length !== 6}
                                            value={VerificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />
                                    </div>

                                    <p className="text-danger  pt-2">{ErrorMsg}</p>
                                    {
                                        IsLoading ? <CircularProgress /> : (
                                            <CustomButton
                                                varient="solid"
                                                className="mt-4"
                                                backgorundColor={basePurple}
                                                width="100%"
                                                height="50px"
                                                hoverbackgroundColor={baseOrange}
                                                styles={{ fontSize: "18px", fontWeight: "bold" }}
                                                noFilter
                                                onClick={() => submitHandlerVerificationCode()}
                                            >
                                                CONTINUE
                                            </CustomButton>
                                        )
                                    }


                                    <p className="mt-4">
                                        <StyledAnchor onClick={() => {
                                            setStep(1)
                                            setIsError(false)
                                            setErrorMsg("")
                                        }}>Go Back</StyledAnchor> &nbsp; &nbsp;
                                    </p>
                                </div>
                            </>
                        ) : null
                    }
                </div>
            </div>
        </>
    )
}

export default ContinueWithKenanForm

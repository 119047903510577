import { useEffect, useState } from 'react'

type RealTimeDeviceDetector = () => {
    IsWeb: boolean;
    IsTab: boolean;
    IsMob: boolean;
}

export const useRealTimeDeviceDetector: RealTimeDeviceDetector = () => {

    const [IsWeb, setIsWeb] = useState<boolean>(window.innerWidth >= 1024)
    const [IsTab, setIsTab] = useState<boolean>(window.innerWidth < 1024 && window.innerWidth >= 768)
    const [IsMob, setIsMob] = useState<boolean>(window.innerWidth < 768)

    useEffect(() => {

        window.addEventListener("resize", () => {
            setIsWeb(window.innerWidth >= 1024)
            setIsTab(window.innerWidth < 1024 && window.innerWidth >= 768)
            setIsMob(window.innerWidth < 768)


        })
    }, [])

    return { IsWeb, IsTab, IsMob }
}
import React, { useEffect } from 'react'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../../store/actions"
import { RootState } from '../../store/root.reducer';
import parse from 'html-react-parser';
import ServiceCard from '../../commonComponets/ServiceCard';
import { useHistory } from "react-router-dom"
import moment from "moment"

const BlogsScreen = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    // const Policy = useSelector((state: RootState) => state.Content.Policy)
    const DriveWithMe = useSelector((state: RootState) => state.Content.BlogNewData)


    // useEffect(() => {
    //     dispatch(Actions.GetPolicy("DriveWithUs"))
    // }, [])


    return (
        <>
            {
                DriveWithMe.map((value, index) => {
                    return <ServiceCard index={index} Data={{
                        Desc:   value.Desc.slice(0,300) + (value.Desc.length > 300 ? "...." : "") ,
                        ImageALT: value.LargeHeading,
                        Img: value.Image_path,
                        LargeHeading: value.LargeHeading,
                        SmallHeading: moment(value.createdAt).format("DD/MM/YYYY"),
                        ActionButtonAction: () => history.push(`/blogs/${value.SmallHeading}`),
                        ActionButtonText: "Read More",
                    }} key={index} />
                })
            }
        </>
    )
}

export default BlogsScreen

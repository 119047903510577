import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import Payments from '../componets/admin/payments/Payments'

const Payment = () => {


    useEffect(()=>{
        document.title = "Payment | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <>
        <Banner title="Payment"/>
        <Payments/>
        <div className="m-5"></div>
        </>
    )
}

export default Payment

import React, { useState, useEffect } from 'react'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../../store/actions"
import { RootState } from '../../store/root.reducer';
import parse from 'html-react-parser';

const BookingsPolicyScreen = () => {

    const dispatch = useDispatch()


    const Policy = useSelector((state: RootState) => state.Content.Policy)


    useEffect(() => {
        dispatch(Actions.GetPolicy("Bookings"))
    }, [])

    return (
        <div className=" pt-5 pb-1 " style={{paddingLeft:"10%",paddingRight:"10%"}}>
            <h2 className="text-center pt-4 mb-5" >
                <ArrowRightIcon /> {Policy.Heading}
            </h2>

            {/* <hr className="pb-" />
            {parse(Policy?.Text || "")} */}

        </div>
    )
}

export default BookingsPolicyScreen

import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import { CareersScreen } from '../componets/careers/CareersScreen'

export const Careers: React.FC = () => {

    useEffect(()=>{
        document.title = "Careers | Proark - Earn Money Driving / Courier With Us Now |"
    },[])
    return (
        <>
            <Banner title="Careers" />
            <CareersScreen />
        </>
    )
}

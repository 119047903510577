// import { Link } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { Link, Redirect } from "react-router-dom"
import { baseOrange, basePurple } from '../../constants'
import { CustomButton } from '../../commonComponets'
import { useSelector, useDispatch } from "react-redux"
import { RootState } from '../../store/root.reducer'
import ContactUsForm from './ContactUsForm'
import * as Actions from "../../store/actions"
const ContactListsScreen: React.FC = () => {

    const dispatch = useDispatch()

    const HeadingsAndList = useSelector((state: RootState) => state.Content.HeadingsAndListing)
    const ContactUs = useSelector((state: RootState) => state.Content.ContactUs)


    // useEffect(() => {
    //     dispatch(Actions.GetContactUs())
    // }, [])


    if(HeadingsAndList.length > 0 &&HeadingsAndList[0].Children.length > 0 ){
        return <Redirect to={`/help/${HeadingsAndList[0].Children[0].ListItemUniqueID}`} /> 
    }

    return (
        <div className="mt-5 mb-5" >
            <div className="container mt-5" >
                <div className="list pt-5"  >
                    {/* <h1 className="listMainHeading" style={{ fontWeight: "bold" }}>
                        {ContactUs.Desc}
                    </h1> */}
                    {
                        HeadingsAndList?.map((value, index) => {
                            return (
                                <div key={value._id} className="ListContent pb-4" style={{ borderBottom: "1px solid #c9c9c9" }} >
                                    <h3 className="listSubHeading mt-4" style={{ fontWeight: "bold" }} >
                                        {value.Heading}
                                    </h3>
                                    <div className="pl-5 pt-3" >
                                        <ul className="mainList">

                                            {
                                                value.Children?.map((value2, index2) => {
                                                    return (
                                                        <li key={value2._id} className="conatact-list-item" >
                                                            <Link className="cont-list-link" to={`/help/${value2.ListItemUniqueID}`} style={{ color: basePurple, fontSize: "18px" }} >
                                                                {value2.ListItem}
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>

                                </div>

                            )
                        })
                    }
                </div>

                <ContactUsForm />

            </div>
        </div>
    )
}

export default ContactListsScreen

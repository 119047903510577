import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import { RequirementsScreen } from '../componets/requirements/RequirementsScreen'

export const Requirements: React.FC = () => {


    useEffect(()=>{
        document.title = "Requirements | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <>
            <Banner title="Requirments" />
            <RequirementsScreen />
        </>
    )
}

import React, { useEffect } from 'react'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../../store/actions"
import { RootState } from '../../store/root.reducer';
import parse from 'html-react-parser';
import ServiceCard from '../../commonComponets/ServiceCard';
import { useHistory } from "react-router-dom"


const AboutUsScreen = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    // const Policy = useSelector((state: RootState) => state.Content.Policy)
    const DriveWithMe = useSelector((state: RootState) => state.Content.AboutUsNewData)


    // useEffect(() => {
    //     dispatch(Actions.GetPolicy("DriveWithUs"))
    // }, [])


    return (
        <>
            {
                DriveWithMe.map((value, index) => {
                    return <ServiceCard index={index} Data={{
                        Desc: value.Desc,
                        ImageALT: value.LargeHeading,
                        Img: value.Image_path,
                        LargeHeading: value.LargeHeading,
                        SmallHeading: value.SmallHeading,
                        ActionButtonAction: () => history.push("/contactUs"),
                        ActionButtonText: index > 1 ? "Learn More" : "More",
                    }} key={index} />
                })
            }
        </>
    )
}

export default AboutUsScreen

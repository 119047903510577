import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../root.reducer';
import { BookingObjINterface, BookingState, GetBookingResponse, UserDataObjInterface, UserState } from "../../interfaces/"


const initialState: BookingState = {
    OnGoingBookning: undefined,
    TotalPages: 0,
    TotalRecords: 0,
    AllBookings: []

};

const userSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        SaveBookingOnGoing: (state, action: PayloadAction<{ booking: BookingObjINterface | undefined }>) => {
            state.OnGoingBookning = action.payload.booking
        },
        GettingAllBookings: (state, action: PayloadAction<{ booking: GetBookingResponse | undefined }>) => {
            state.TotalPages = action.payload.booking?.TotalPages || 0
            state.TotalRecords = action.payload.booking?.TotalRecords || 0
            state.AllBookings = action.payload.booking?.Bookings || []
        }


    },
});

export const { SaveBookingOnGoing, GettingAllBookings } = userSlice.actions;

export default userSlice.reducer;


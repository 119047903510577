export const otherPurple = "rgb(234, 11, 140)";
export const basePurple = "rgb(234, 11, 140)";
export const baseOrange = "rgb(255, 121, 6)";
export const baseGreen = "rgb(0, 180, 55)";
export const grey = "rgb(128, 128, 128)";
export const black = "rgb(0, 0, 0)";
export const white = "rgb(255, 255, 255)";



export const Endpoint = "https://backend.proark.ca";
export const END_POINT_IP = "http://54.71.31.71:3000"


export const AndroidApp = "https://play.google.com/store/apps/details?id=com.delivery.kenancourier.driver"
export const IOSApp = ""



// export const SquareupScriptLink = "https://js.squareupsandbox.com/v2/paymentform" // SandBox
// export const SquareupApplicationID = "sandbox-sq0idb-5O9vQ0G3kD9BH421TsvpIQ" // SandBox

export const SquareupApplicationID = "sq0idp-f8-JRrBLelFdLT36pVq_-Q" //Real
export const SquareupScriptLink = "https://js.squareup.com/v2/paymentform" //Real


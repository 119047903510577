import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import SelectRider from '../componets/admin/selectRider/SelectRider'

const SelectRiderSimple = () => {

    useEffect(()=>{
        document.title = "Select Rider | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <div style={{height:"auto"}}>
        <Banner title="Select Rider" />
        <SelectRider/>
        </div>
    )
}

export default SelectRiderSimple

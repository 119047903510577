import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BannerPropsInterface } from '../interfaces'
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { RootState } from '../store/root.reducer'
import DefaultImage from "../assets/images/bread-bg.jpg"
const Banner: React.FC<BannerPropsInterface> = (props) => {

    const location = useLocation()

    const [Image, setImage] = useState("")
    const [Title, setTitle] = useState("")

    const pages = useSelector((state: RootState) => state.Content.PageTitleAndImage);

    useEffect(() => {
        let pathname = location.pathname;
        if(pathname === "/drive-with-us"){
            pathname = "/courier-with-us"
        }
        // if(pathname === "/continueWithProark"){
        //     pathname = "/login"
        // }
        if(pathname === "/becomeDriver"){
            pathname = "/courier-with-us"
        }
        const ThisPage = pages.filter(x => {
            return x.PageRoute === pathname
        })[0] || {}
        setImage(ThisPage.Image)
        setTitle(ThisPage.Title )
        document.title = ThisPage.Title || "Proarks"


    }, [location.pathname, pages])

    return (
        <section className="banner" style={{ backgroundImage: `url(${props.Image || Image || DefaultImage})` }}>
            <div className="overLay">
                <div className="bannerBox">
                    <h1 className="bannerMainHeading" style={{fontSize: location.pathname.split("/").includes("blogs") ? "25px":""}}>
                        {Title || props.title}
                    </h1>
                    <h6 className="breadCrum">
                        {/* <Link to="/">
                            <span className="home">Home</span>
                        </Link> / <span>{Title || props.title} </span> */}

                    </h6>
                </div>
            </div>
        </section>
    )
}

export default Banner

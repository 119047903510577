import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import BookingScreen from '../componets/booking/BookingScreen'
import LegalDelieveryForms from '../componets/legalDelievery/LegalDelieveryForms'
import LegalDelieveryScreen from '../componets/legalDelievery/LegalDelieveryScreen'

const LegalDelievery : React.FC= () => {

    useEffect(()=>{
        document.title = "Legal Delivery | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
       <div style={{background:"rgb(244, 244, 244)"}}>
       <Banner title="Process Serving" />
       <LegalDelieveryScreen/>
       {/* <BookingScreen/> */}
       <LegalDelieveryForms/>
       </div>
    ) 
}

export default LegalDelievery

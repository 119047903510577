import React from "react"
import { ErrorBoundary } from 'react-error-boundary'
import { useHistory } from "react-router-dom"
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const ErrorBoundaryHelper: React.FC = (props) => {

  const History = useHistory()

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
        History.go(0)
      }}
    >
      {
        props.children
      }
    </ErrorBoundary>
  )
}


export default  ErrorBoundaryHelper
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './store/root.reducer';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/styles.scss"
import MyMUITheme from './helpers/MyMuiHelper';
import { BrowserRouter as Router, } from "react-router-dom"
import ErrorBoundaryHelper from "./helpers/ErrorBoundary"
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ErrorBoundaryHelper>
          <App />
        </ErrorBoundaryHelper>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import Banner from '../commonComponets/Banner'
import { ContentInterface } from '../interfaces'
import { RootState } from '../store/root.reducer'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import parse from 'html-react-parser';
import { baseOrange, basePurple } from '../constants'
import { CircularProgress, TextField } from '@material-ui/core'
import { CustomButton } from '../commonComponets'
import { useDispatch } from "react-redux"
import * as Actions from "../store/actions"
import { toast } from 'react-toastify'
const CareerDetail: React.FC = props => {


    const dispatch = useDispatch();


    const { id } = useParams<{ id: string }>()

    const [Data, setData] = useState<ContentInterface | null>(null)

    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Phone, setPhone] = useState("");
    const [Qualification, setQualification] = useState("")
    const [YearsOfExperience, setYearsOfExperience] = useState("")
    const [Resume, setResume] = useState<any>()
    const [IsError, setIsError] = useState(false)
    const [ErrorMsg, setErrorMsg] = useState("")
    const [IsLoading, setIsLoading] = useState(false)



    console.log(Resume?.name)

    const OurTeam = useSelector((state: RootState) => state.Content.OurTeam)

    useEffect(() => {
        if (OurTeam.length > 0) {
            const data = OurTeam.filter((x) => x.LargeHeading === id.replace(/\-/g, " ").replace(/\_/g, "/"))[0]
            if (data) {
                setData(data)
            }
        }
    }, [OurTeam])

    useEffect(()=>{
        document.title = `${Data?.LargeHeading || "Careers"} | Proark - Earn Money Driving / Courier With Us Now |`
    },[Data])

    async function submitForm() {
   

        // return setResume(null)
        try {
            setIsError(false)
            setErrorMsg("")
            setIsLoading(true)

            if (!Name || !Email || !Phone || !Qualification || !YearsOfExperience || !Resume) throw "Please fill all the fields"

            if(Phone.length > 11 || Phone.length < 10) throw "Please enter a valid phone no"

            const res = await dispatch(Actions.SubmitJobRequest(Data?.LargeHeading, Name, Email, Phone, Qualification, YearsOfExperience, Resume))
            if (res) throw res
            toast.success("Your Job Request has been submitted!")
            setIsError(false)
            setErrorMsg("")
            setIsLoading(false)

            setName("")
            setEmail("")
            setPhone("")
            setQualification("")
            setYearsOfExperience("")
            setResume(null)

            let check :any = document.getElementById('ProofOfWorkEligibility') && document.getElementById('ProofOfWorkEligibility')
            check.value= null;
        } catch (error) {
            setIsError(true)
            setErrorMsg(typeof error === "string" ? error : "")
            setIsLoading(false)

        }

    }

    if (!Data) return <>loading...</>

    return (
        <div>
            <Banner title={Data.LargeHeading} Image={Data.Image_path} />
            <div className="container pt-5 pb-5 mb-5">
                <h2 className="text-center pt-4 mb-5" >
                    {/* <ArrowRightIcon /> { Data.Heading } */}
                </h2>

                {/* <hr className="pb-4" /> */}

                {
                    parse(Data?.Desc || "")
                }

                <h2 className="text-center pt-4 mb-5" style={{ color: baseOrange }}>
                    <ArrowRightIcon /> Apply Now
                </h2>

                <div className="row mt-5">
                    <div className="col-lg-6 col-md-12 mt-2">
                        <div className="form-group">
                            <TextField
                                variant="outlined"
                                style={{ height: "54px" }}
                                className="form-control"
                                label="Enter Name"
                                error={IsError && !Name}
                                value={Name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 mt-2">
                        <div className="form-group">
                            <TextField
                                variant="outlined"
                                style={{ height: "54px" }}
                                className="form-control"
                                label="Enter Email"
                                error={IsError && !Email}
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 mt-2">
                        <div className="form-group">
                            <TextField
                                variant="outlined"
                                style={{ height: "54px" }}
                                className="form-control"
                                label="Enter Phone"
                                type="number"
                                error={IsError && !Phone }
                                value={Phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 mt-2">
                        <div className="form-group">
                            <TextField
                                variant="outlined"
                                style={{ height: "54px" }}
                                className="form-control"
                                label="Enter Qualification"
                                type="text"
                                error={IsError && !Qualification}
                                value={Qualification}
                                onChange={(e) => setQualification(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 mt-2">
                        <div className="form-group">
                            <TextField
                                variant="outlined"
                                style={{ height: "54px" }}
                                className="form-control"
                                label="Enter Your years of experience"
                                type="number"
                                error={IsError && !YearsOfExperience}
                                value={YearsOfExperience}
                                onChange={(e) => setYearsOfExperience(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mt-2">
                        <div className="form-group ">
                            <label htmlFor="ProofOfWorkEligibility" style={{ textAlign: "left", width: "100%" }}>Upload Resume*</label>
                            <input
                                id="ProofOfWorkEligibility"
                                style={{ height: "44px" }}
                                type="file"
                                accept=".pdf, .doc, .docx"
                                placeholder="Address "
                                required
                                // value={Resume?.name || ""}
                                className={`form-control ${IsError && !Resume ? "errorInput" : ""}`}
                                onChange={(e) => setResume(e.target.files && e.target.files[0])}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 ">
                        <p className="text-danger  pt-1 w-100">{ErrorMsg}</p>
                        <br />
                        {
                            IsLoading ? <div className="w-100 d-flex justify-content-center align-items-center">
                                <CircularProgress className="text-center" />
                            </div> : (
                                <CustomButton
                                    varient="solid"
                                    className=""
                                    backgorundColor={basePurple}
                                    width="100%"
                                    height="50px"
                                    hoverbackgroundColor={baseOrange}
                                    styles={{ fontSize: "18px", fontWeight: "bold" }}
                                    noFilter
                                    onClick={() => submitForm()}
                                >
                                    Apply Now
                                </CustomButton>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CareerDetail

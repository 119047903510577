import React, { useState, useEffect } from 'react'
import Styled from "styled-components";
import { CustomButton } from '../../commonComponets'
import { baseOrange, basePurple, white } from '../../constants'
import { Link, Redirect, useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom"
import * as EmailValidator from 'email-validator';
import { CircularProgress } from '@material-ui/core';
import * as Actions from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/root.reducer";
import GooglePlacesCommon from '../../commonComponets/GooglePlacesCommon';

const StyledAnchor = Styled.a`
    color:${basePurple};

    &:hover{
        color:${baseOrange};
        cursor:pointer;
    }
`

const SignUpForm: React.FC = (props) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const location = useLocation()

    const [FirstName, setFirstName] = useState<string>("")
    const [LastName, setLastName] = useState<string>("")
    const [Email, setEmail] = useState<string>("")
    const [PhoneNo, setPhoneNo] = useState<string>("")
    const [Password, setPassword] = useState<string>("")
    const [Address, setAddress] = useState<string>("")
    const [AgreeTerms, setAgreeTerms] = useState<boolean>(false)
    const [IsError, setIsError] = useState<boolean>(false)
    const [IsLoading, setIsLoading] = useState<boolean>(false)
    const [ErrorMsg, setErrorMsg] = useState<string>("")


    const IsLogin = useSelector((state: RootState) => state.User.IsLogin)

    useEffect(()=>{
        if(sessionStorage.getItem("@Addresses")){
            setAddress(JSON.parse(sessionStorage.getItem("@Addresses") || "").AddressFrom)
        }
    },[])

    async function submitHandler(): Promise<void> {
        setIsError(false)
        setErrorMsg("")
        if (IsLoading) return;
        try {
            if (!Email || !Password || !FirstName || !LastName || !PhoneNo || !Address) throw "Please fill all the required fields"
            if (PhoneNo.length > 11 || PhoneNo.length < 10 ) throw "Please enter a valid Phone No"
            if (!EmailValidator.validate(Email)) throw "Please enter a valid email address"
            if (!AgreeTerms) throw "Please accept our terms and conditions"
            setIsLoading(true)
            const res = await dispatch(Actions.RegisterAction(FirstName, LastName, Email, PhoneNo, Password, Address, history, location))
            if (res) throw res
        } catch (error) {
            console.log(error)
            setIsError(true);
            setErrorMsg(error)
            setIsLoading(false)

        }
        // history.push("/admin/user")
    }
    if (IsLogin) return <Redirect to="/admin/user" />
    return (
        <>
            <div className="container signup-form " >
            <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        {/* <h1>Sign Up</h1> */}
                        <div className="row mt-5">
                            <div className="col-lg-6 col-md-12 mt-2">
                                <div className="form-group">
                                    <input
                                        style={{ height: "54px" }}
                                        type="text" placeholder="First Name *"
                                        required
                                        className={`form-control ${IsError && !FirstName ? "errorInput" : ""}`}
                                        value={FirstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <div className="form-group">
                                    <input
                                        style={{ height: "54px" }}
                                        type="text" placeholder="Last Name *"
                                        required
                                        className={`form-control ${IsError && !LastName ? "errorInput" : ""}`}
                                        value={LastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-lg-12 mt-2">
                                <input
                                    style={{ height: "54px" }}
                                    type="text"
                                    placeholder="Enter Email *"
                                    required
                                    className={`form-control ${IsError && !Email ? "errorInput" : ""}`}
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group col-lg-12 mt-2">
                                <input
                                    style={{ height: "54px" }}
                                    type="number"
                                    placeholder="Phone Number *"
                                    required
                                    className={`form-control ${IsError && !PhoneNo ? "errorInput" : ""}`}
                                    value={PhoneNo}
                                    onChange={(e) => setPhoneNo(e.target.value)}
                                />
                            </div>

                            <div className="form-group col-lg-12 mt-2">
                                <input
                                    style={{ height: "54px" }}
                                    type="password"
                                    placeholder="Create Password *"
                                    required
                                    autoComplete="new-password"
                                    className={`form-control ${IsError && !Password ? "errorInput" : ""}`}
                                    value={Password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-group col-lg-12 mt-2">
                                {/* <input
                                    style={{ height: "54px" }}
                                    
                                    placeholder="Enter Street Address *"
                                    required
                                    className={`form-control ${IsError && !Address ? "errorInput" : ""}`}
                                    value={Address}
                                    onChange={(e) => setAddress(e.target.value)}
                                /> */}
                                <GooglePlacesCommon
                                placeholder="Enter Street Address *"
                                setValue={(e) => setAddress(e)}
                                value={Address}
                                />
                            </div>
                        </div>
                        <div className="form-group text-left mt-2 ml-4">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck"
                                checked={AgreeTerms}
                                onClick={() => setAgreeTerms(!AgreeTerms)}
                            />
                            <label
                                style={{ fontWeight: 400, fontSize: "17px" }}
                                className="form-check-label"
                            >
                                By clicking Sign Up, you agree to our  <StyledAnchor href="/termsAndConditions" target="blank" >Terms</StyledAnchor> and <StyledAnchor href="/privacyPolicy" target="blank">Data Policy</StyledAnchor>  .
                            </label>
                        </div>
                        <p className="text-danger  pt-2">{ErrorMsg}</p>


                        {
                            IsLoading ? <CircularProgress /> : (
                                <CustomButton
                                    varient="solid"
                                    className="mt-3"
                                    backgorundColor={basePurple}
                                    width="100%"
                                    height="50px"
                                    hoverbackgroundColor={baseOrange}
                                    styles={{ fontSize: "18px", fontWeight: "bold" }}
                                    noFilter
                                    onClick={() => submitHandler()}
                                >
                                    SIGN UP
                                </CustomButton>
                            )
                        }
                        <p className="mt-4">
                            Want to use your phone no? &nbsp;
                            <StyledAnchor onClick={() => history.push("/continueWithProark")}>Continue</StyledAnchor> &nbsp; &nbsp;
                        </p>
                        <p className="mt-4  mb-5">
                            Already have an account? &nbsp;
                         <StyledAnchor onClick={() => history.push("login")}>Sign in</StyledAnchor> &nbsp; &nbsp;
                        <StyledAnchor onClick={() => history.push("forgotpassword")}>Forgot Password?</StyledAnchor>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUpForm

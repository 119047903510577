import React from 'react'
import Styled from "styled-components";
import { CustomButtonComponetPropsInterface } from "../interfaces"
import { baseGreen, baseOrange, basePurple, white } from "../constants"



export const CustomButton: React.FC<CustomButtonComponetPropsInterface> = (props) => {

    const ButtonSolid = Styled.button`
        height: ${props.height || "38px"};
        width: ${props.width || "auto"};
        background: ${props.backgorundColor || baseOrange};
        color: ${props.textColor || white};
        border: none;
        border-radius: 5px;
        padding: 0 10px;
        transition:.2s;

        &:hover{
            filter: ${props.noFilter ? "" : "brightness(85%)"};
            background: ${props.hoverbackgroundColor || props.backgorundColor || basePurple};
            color: ${props.hoverTextColor || props.textColor || white};
        }
    `;

    const ButtonOutlined = Styled.button`
        height: ${props.height || "38px"};
        width: ${props.width || "auto"};
        background: none;
        color: ${props.textColor || baseOrange};
        border: 2px solid ${props.backgorundColor || baseOrange};
        border-radius: 5px;
        padding: 0 10px;
        transition:.2s;

        &:hover{
            background: ${props.backgorundColor || baseOrange};
            color: ${props.hoverTextColor || white};
        }
    `;
    if (props.varient == "outlined") {
        return (
            <ButtonOutlined
                id={props.id || ""}
                className={props.className || ""}
                onClick={props.onClick}
                style={{ ...props.styles }}
            >
                {props.children}
            </ButtonOutlined>
        )
    }
    return (
        <ButtonSolid
            id={props.id || ""}
            className={props.className || ""}
            onClick={props.onClick}
            style={{ ...props.styles }}
        >
            {props.children}
        </ButtonSolid>
    )
}



import React,{useEffect} from 'react'
import Banner from '../commonComponets/Banner'
import AboutUsScreen from '../componets/aboutus/AboutUsScreen'

const AboutUs:React.FC = () => {

    useEffect(()=>{
        document.title = "About Us | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <>
            <Banner title="About Us" />
            <AboutUsScreen/>
        </>
    )
}

export default AboutUs

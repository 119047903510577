import React, { useEffect, useState } from 'react'
import { CustomButton } from '../../../commonComponets'
import { baseOrange, basePurple, SquareupApplicationID, } from '../../../constants'
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/root.reducer"
import * as Actions from "../../../store/actions"
import { toast } from 'react-toastify'
import { useLocation } from "react-router-dom"
import { CircularProgress } from '@material-ui/core'
// import config from "../../../helpers/PaymentConfig"

const PaymentForm: React.FC<{ paymentForm: any }> = (props) => {

    const history = useHistory()
    const location = useLocation()

    const dispatch = useDispatch();

    const [IsLoading, setIsLoading] = useState(false)
    const [DriverTip, setDriverTip] = useState<any>(0)

    const onGoingBooking = useSelector((state: RootState) => state.Booking.OnGoingBookning)


    const config = {
        // Initialize the payment form elements

        //TODO: Replace with your sandbox application ID
        // applicationId: "sq0idp-f8-JRrBLelFdLT36pVq_-Q", // real
        applicationId: SquareupApplicationID,
        inputClass: 'sq-input',
        autoBuild: false,
        // Customize the CSS for SqPaymentForm iframe elements
        // Initialize the credit card placeholders
        cardNumber: {
            elementId: 'sq-card-number',
            placeholder: '1234 1234 1234 1234'
        },
        cvv: {
            elementId: 'sq-cvv',
            placeholder: 'CVV'
        },
        expirationDate: {
            elementId: 'sq-expiration-date',
            placeholder: 'MM / YY'
        },
        postalCode: {
            elementId: 'sq-postal-code',
            placeholder: 'Postal Code'
        },
        // SqPaymentForm callback functions
        callbacks: {
            /*
            * callback function: cardNonceResponseReceived
            * Triggered when: SqPaymentForm completes a card nonce request
            */
            paymentFormLoaded: function () {
                /* HANDLE AS DESIRED */
            },
            cardNonceResponseReceived: async function (errors: any, nonce: any, cardData: any) {
                setIsLoading(false)
                if (errors) {
                    // Log errors from nonce generation to the browser developer console.
                    console.error('Encountered errors:');
                    errors.forEach(function (error: any) {
                        console.error('  ' + error.message);
                    });
                    Swal.fire({
                        title: 'Error!',
                        text: errors[0].message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                    // alert('Encountered errors, check browser developer console for more details');
                    return;
                }
                console.log(`The generated nonce is:\n${nonce}`);
                console.log(cardData);
                CallApi(nonce, cardData)

                //make your api call here
            }
        }
    }



    const paymentForm: any = new props.paymentForm(config);

    paymentForm.build();

    const requestCardNonce = () => {
        setIsLoading(true)
        paymentForm.requestCardNonce();
    }

    const CallApi = async (nonce: string, cardData: any) => {
        setIsLoading(true)
        const res69: any = await dispatch(Actions.MakePayment(nonce, DriverTip, cardData))
        setIsLoading(false)
        if (res69) {
            Swal.fire({
                title: 'Error!',
                text: res69,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        } else {
            toast.success("Payment successful!")

            if (location.pathname.split("/").includes("admin")) return history.push(`/admin/bookingDetails/${onGoingBooking?.TrackingID}`)
            history.push(`/bookingDetails/${onGoingBooking?.TrackingID}`)

        }

    }


    return (

        <div className={location.pathname.split("/").includes("admin") ? "pl-4 pr-4 pt-5" : " pt-5 container"}>
            <div className="row">
                <div className="col-lg-12 col-md-6">
                    <h1>Add Card</h1>
                </div>
            </div>
            <div className="show_add_card">
                <div className="row pt-4">
                    <div className="col-lg-3 mt-3">
                        <div className="form-group">
                            <label style={{ fontWeight: 600 }} >Total Amount</label>
                        </div>
                    </div>

                    <div className="col-lg-9 mt-3">
                        <div className="form-group">
                            <input style={{ height: "50px" }} value={"$" + onGoingBooking?.TotalFee} type="text" placeholder="Total Amount" disabled className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-3 mt-3">
                        <div className="form-group">
                            <label style={{ fontWeight: 600 }} >Driver's Tip</label>
                        </div>
                    </div>

                    <div className="col-lg-9 mt-3">
                        <div className="form-group">
                            <input style={{ height: "50px" }} value={DriverTip} onChange={(e) => setDriverTip(e.target.value.replace(/\$/g, ""))} type="text" placeholder="Total Amount" className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-3 mt-3">
                        <div className="form-group">
                            <label style={{ fontWeight: 600 }} >Name</label>
                        </div>
                    </div>

                    <div className="col-lg-9 mt-3">
                        <div className="form-group">
                            <input style={{ height: "50px" }} type="text" placeholder="Name" required={true} className="form-control" />
                        </div>
                    </div>


                    <div className="col-lg-3 mt-3">
                        <div className="form-group">
                            <label style={{ fontWeight: 600 }} >Card Number</label>
                        </div>
                    </div>

                    <div className="col-lg-9 mt-3">
                        <div className="form-group">
                            <input id="sq-card-number" style={{ height: "50px" }} type="text" placeholder="1234 1234 1234 1234" required={true} className="form-control" />
                        </div>
                    </div>

                    <div className="col-lg-3 mt-3">
                        <div className="form-group">
                            <label style={{ fontWeight: 600 }} >CVV</label>
                        </div>
                    </div>

                    <div className="col-lg-9 mt-3">
                        <div className="form-group">
                            <input id="sq-cvv" style={{ height: "50px" }} type="text" placeholder="CVV" required={true} className="form-control" />
                        </div>
                    </div>


                    <div className="col-lg-3 mt-3">
                        <div className="form-group">
                            <label style={{ fontWeight: 600 }} >Expiry</label>
                        </div>
                    </div>

                    <div className="col-lg-9 mt-3">
                        <div className="form-group">
                            <input id="sq-expiration-date" style={{ height: "50px" }} type="text" placeholder="MM / YY" required={true} className="form-control" />
                        </div>
                    </div>






                    <div className="col-lg-3 mt-3">
                        <div className="form-group">
                            <label style={{ fontWeight: 600 }} >Postal Code</label>
                        </div>
                    </div>

                    <div className="col-lg-9 mt-3">
                        <div className="form-group">
                            <input id="sq-postal-code" style={{ height: "50px" }} type="text" placeholder="Postal Code" required={true} className="form-control" />
                        </div>
                    </div>


                    {
                        IsLoading ? <CircularProgress /> : (
                            <CustomButton
                                varient="solid"
                                className=" col-lg-3 mt-2 p-2"
                                backgorundColor={basePurple}
                                width="98%"
                                height="45px"
                                hoverbackgroundColor={baseOrange}
                                styles={{ fontSize: "18px", fontWeight: "bold" }}
                                noFilter
                                onClick={() => requestCardNonce()}
                            >
                                Place Order
                            </CustomButton>
                        )
                    }

                    {/* <div className="col-lg-3">
                            <button type="submit" className="btn btn-block btn-primary">Place Order</button>
                        </div> */}
                </div>

            </div>


        </div>

    )
}

export default PaymentForm

import React from 'react'
import { OurTeams } from "./OurTeams";
import { WorkingWithKenan } from "./WorkingWithKenan";
import { OurLocations } from "./OurLocations";

export const CareersScreen: React.FC = () => {
    return (
        <>
            <OurTeams />
            {/* <OurLocations /> */}
            <WorkingWithKenan />
        </>
    )
}

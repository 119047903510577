import React, { useState, useEffect } from 'react'
import ServiceCard from '../../commonComponets/ServiceCard'
import { ServiceCardData } from '../../interfaces'
import ServiceImg_1 from "../../assets/images/ser2-1.jpg"
import ServiceImg_2 from "../../assets/images/ser2-2.jpg"
import DialogWrapper from "../../commonComponets/DialogWrapper"
import { Button } from 'reactstrap'
import { CustomButton } from "../../commonComponets"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/root.reducer";
import { baseOrange, basePurple } from '../../constants'
import RequestPickupDialog from './RequestPickupDialog'
import { useRealTimeDeviceDetector } from "../../helpers/useRealTimeDeviceDetector"
import * as Actions from "../../store/actions"

const LegalDelieveryForms: React.FC = () => {

    const { IsMob, IsTab } = useRealTimeDeviceDetector()


    const history = useHistory()
    const dispatch = useDispatch()

    const [IsRequestPickupOpen, setIsRequestPickupOpen] = useState<boolean>(false)
    const [SelectedPrice, setSelectedPrice] = useState<number>(0)
    const [SelectedTab, setSelectedTab] = useState<number>(0)
    const [Step, setStep] = useState<number>(0)

    const [Name, setName] = useState<string>("")
    const [PhoneNo, setPhoneNo] = useState<string>("")
    const [Email, setEmail] = useState<string>("")
    const [Address, setAddress] = useState<string>("")
    const [DefendentName, setDefendentName] = useState<string>("")
    const [DefendentPhoneNo, setDefendentPhoneNo] = useState<string>("")
    const [DefendentEmail, setDefendentEmail] = useState<string>("")
    const [DefendentAddress, setDefendentAddress] = useState<string>("")
    const [WhatToDeliever, setWhatToDeliever] = useState<string>("")
    const [Files, setFiles] = useState<any>([])
    const [Error1, setError1] = useState<string>("")
    const [Error2, setError2] = useState<string>("")
    const [Error3, setError3] = useState<string>("")
    const [Success1, setSuccess1] = useState<string>("")
    const [Success2, setSuccess2] = useState<string>("")
    const [Success3, setSuccess3] = useState<string>("")

    const LogisiticsPage = useSelector((state: RootState) => state.Content.LogisiticsPage)
    const PricingData = useSelector((state: RootState) => state.Content.PricingData)

    useEffect(() => {
        setName("")
        setPhoneNo("")
        setEmail("")
        setAddress("")
        setDefendentName("")
        setDefendentPhoneNo("")
        setDefendentEmail("")
        setDefendentAddress("")
        setWhatToDeliever("")
        setError1("")
        setError2("")
        setError3("")
        setSuccess1("")
        setSuccess2("")
        setSuccess3("")
    }, [SelectedTab])


    return (
        <>
            <section className={`serviceSection `} style={{ backgroundColor: "white", height: "520px" }} >
                <div className="serviceDivContainer">
                    <div
                        style={{
                            background: `url(https://res.cloudinary.com/kenan-courier/image/upload/v1616984930/fpahfkdmuzcfj3zaqnhr.jpg)`,
                            backgroundSize: "cover"
                        }}
                        className="serviceDivLeft"
                    />

                    <div className="serviceDivRight d-flex justify-content-center align-items-center " style={{ flexDirection: "column", padding: "21px 22px" }} >
                        <p style={{ fontSize: "13.4px" }}>
                            We charge a FLAT RATE as indicated below for each process service, however, each ADDITIONAL process service at the same address at the same time is only $20.00, no matter in which area it is served. The only additional charges are for commissioning $30,00, notarization $25.00 Plus Applicable Taxes – Mileage Included in Rates
                    </p>
                        <div className="accordian" style={{ border: "1px solid #c9c9c9", width: "100%" }} >
                            <div className="d-flex text-center" style={{ width: "100%", flexWrap: "wrap" }} >
                                {
                                    PricingData.map((value, index) => {
                                        return (
                                            <span key={value._id} className={`accordian-tabs-hover ${SelectedPrice === index ? "accordian-tab-active" : ""}`} onClick={() => setSelectedPrice(index)} style={{ flex: "1", fontWeight: "bold", cursor: "pointer", padding: "10px 15px", borderLeft: "1px solid #c9c9c9" }} >
                                                {value.Heading}
                                            </span>
                                        )
                                    })
                                }


                            </div>
                            <div className="data-list" style={{ borderTop: "1px solid #c9c9c9", backgroundColor: "white", width: "100%", display: "flex", flexWrap: "wrap" }} >
                                {
                                    PricingData[SelectedPrice]?.Cities.map((value, index) => {
                                        return (
                                            <span key={index} style={{ padding: "10px 15px", width: IsMob ? "50%" : "25%" }} >
                                                {value}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`serviceSection invert`} style={{ backgroundColor: "rgb(244, 244, 244)" }} >
                <div className="serviceDivContainer">
                    <div
                        style={{
                            background: `url(https://res.cloudinary.com/kenan-courier/image/upload/v1616984831/zg3qdppeuk6nymfaeboh.jpg)`,
                            backgroundSize: "cover"
                        }}
                        className="serviceDivLeft"
                    />


                    <div className="serviceDivRight d-flex justify-content-center align-items-center">
                        <div className="accordian" style={{ border: "1px solid #c9c9c9", width: "100%" }} >
                            <div className="d-flex text-center" style={{ width: "100%", flexWrap: "wrap" }} >
                                <span onClick={() => setSelectedTab(0)} className={` ${SelectedTab === 0 ? "accordian-tab-active" : ""}`} style={{ flex: "1", fontWeight: "bold", cursor: "pointer", padding: "10px 15px", borderLeft: "1px solid #c9c9c9", backgroundColor: "white" }} >
                                    Pickup
                                </span>
                                <span onClick={() => setSelectedTab(1)} className={` ${SelectedTab === 1 ? "accordian-tab-active" : ""}`} style={{ flex: "1", fontWeight: "bold", cursor: "pointer", padding: "10px 15px", borderLeft: "1px solid #c9c9c9", backgroundColor: "white" }} >
                                    Drop Off
                                </span>
                                <span onClick={() => setSelectedTab(2)} className={` ${SelectedTab === 2 ? "accordian-tab-active" : ""}`} style={{ flex: "1", fontWeight: "bold", cursor: "pointer", padding: "10px 15px", borderLeft: "1px solid #c9c9c9", backgroundColor: "white" }} >
                                    Email Us
                                </span>
                            </div>
                            <div className="data-list" style={{ borderTop: "1px solid #c9c9c9", backgroundColor: "white", width: "100%", display: "flex", flexWrap: "wrap", height: "400px", overflowY: SelectedTab === 2 ? "scroll" : "hidden" }} >
                                <div className="row" style={{ width: "100%", justifyContent: "center", alignItems: "center" }} >
                                    <div className="col-lg-9 text-center mt-1">
                                        {/* {Success1 ? <p className="text-success  pt-0   m-0">{Success1}</p> : null} */}

                                        {
                                            SelectedTab === 0 ? (
                                                Success1 ? <p className="text-success  pt-0   m-0">{Success1}</p> : (
                                                    <>
                                                        <div className="form-group mt-1 ">
                                                            <p>
                                                                Enter your details and a rep will contact you shortly to prove our office details.
                                                            </p>
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Name *"
                                                                required
                                                                className="form-control"
                                                                value={Name}
                                                                onChange={e => setName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="number"
                                                                placeholder="Phone No *"
                                                                required
                                                                className="form-control"
                                                                value={PhoneNo}
                                                                onChange={e => setPhoneNo(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="email"
                                                                placeholder="Email"
                                                                required
                                                                className="form-control"
                                                                value={Email}
                                                                onChange={e => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Address"
                                                                required
                                                                className="form-control"
                                                                value={Address}
                                                                onChange={e => setAddress(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Type of document to pickup "
                                                                required
                                                                className="form-control"
                                                                value={WhatToDeliever}
                                                                onChange={e => setWhatToDeliever(e.target.value)}
                                                            />
                                                        </div>
                                                        {Error1 ? <p className="text-danger  pt-0   m-0">{Error1}</p> : null}

                                                        <CustomButton
                                                            varient="solid"
                                                            className="mt-1"
                                                            backgorundColor={basePurple}
                                                            width="100%"
                                                            height="30px"
                                                            hoverbackgroundColor={baseOrange}
                                                            styles={{ fontSize: "15px", fontWeight: "bold" }}
                                                            noFilter
                                                            onClick={async () => {
                                                                setError1("")
                                                                setSuccess1("")
                                                                const res: any = await dispatch(Actions.PickUpOrder(Name, PhoneNo, Email, Address, WhatToDeliever))
                                                                if (!res.Error) {
                                                                    // setSelectedTab(1)
                                                                    setName("")
                                                                    setPhoneNo("")
                                                                    setEmail("")
                                                                    setAddress("")
                                                                    setWhatToDeliever("Document")
                                                                    setSuccess1(res.Msg)
                                                                } else {
                                                                    setError1(res.Msg)
                                                                }
                                                            }}
                                                        >
                                                            Submit
                                                    </CustomButton>
                                                    </>

                                                )
                                            ) : null
                                        }

                                        {
                                            SelectedTab === 1 ? (
                                                Success2 ? <p className="text-success  pt-0   m-0">{Success2}</p> : (
                                                    <>
                                                        <div className="form-group mt-1 ">
                                                            <p>
                                                                Enter your details and a rep will contact you shortly to prove our office details.
                                                            </p>
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Name *"
                                                                required
                                                                className="form-control"
                                                                value={Name}
                                                                onChange={e => setName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="number"
                                                                placeholder="Phone No *"
                                                                required
                                                                className="form-control"
                                                                value={PhoneNo}
                                                                onChange={e => setPhoneNo(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="email"
                                                                placeholder="Email"
                                                                required
                                                                className="form-control"
                                                                value={Email}
                                                                onChange={e => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        {/* <div className="form-group mt-1 ">
                                                    <input
                                                        style={{ height: "35px" }}
                                                        type="text"
                                                        placeholder="Address *"
                                                        required
                                                        className="form-control"
                                                        value={Address}
                                                        onChange={e => setAddress(e.target.value)}
                                                    />
                                                </div> */}
                                                        {Error2 ? <p className="text-danger  pt-0   m-0">{Error2}</p> : null}
                                                        {Success2 ? <p className="text-success  pt-0   m-0">{Success2}</p> : null}
                                                        <CustomButton
                                                            varient="solid"
                                                            className="mt-1"
                                                            backgorundColor={basePurple}
                                                            width="100%"
                                                            height="30px"
                                                            hoverbackgroundColor={baseOrange}
                                                            styles={{ fontSize: "15px", fontWeight: "bold" }}
                                                            noFilter
                                                            onClick={async () => {
                                                                const res: any = await dispatch(Actions.DropoffOrder(Name, PhoneNo, Email, Address))
                                                                if (!res.Error) {
                                                                    // setSelectedTab(2)
                                                                    setName("")
                                                                    setPhoneNo("")
                                                                    setEmail("")
                                                                    setAddress("")
                                                                    setWhatToDeliever("Document")
                                                                    setSuccess2(res.Msg)
                                                                } else {
                                                                    setError2(res.Msg)

                                                                }
                                                            }}
                                                        >
                                                            Submit
                                                </CustomButton>
                                                    </>

                                                )
                                            ) : null
                                        }
                                        {/* {Success3 ? <p className="text-success  pt-0   m-0">{Success3}</p> : null} */}

                                        {
                                            SelectedTab === 2 ? (
                                                Success3 ? <p className="text-success  pt-0   m-0">{Success3}</p> : (
                                                    <div style={{ width: "100%", height: "100%" }}>
                                                        <div className="form-group mt-1 ">
                                                            <p>
                                                                Enter your details and a rep will contact you shortly to prove our office details.
                                                            </p>
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Name *"
                                                                required
                                                                className="form-control"
                                                                value={Name}
                                                                onChange={e => setName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Phone No *"
                                                                required
                                                                className="form-control"
                                                                value={PhoneNo}
                                                                onChange={e => setPhoneNo(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="email"
                                                                placeholder="Email"
                                                                required
                                                                className="form-control"
                                                                value={Email}
                                                                onChange={e => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        {/* <div className="form-group mt-1 ">
                                                    <input
                                                        style={{ height: "35px" }}
                                                        type="text"
                                                        placeholder="Address *"
                                                        required
                                                        className="form-control"
                                                        value={Address}
                                                        onChange={e => setAddress(e.target.value)}
                                                    />
                                                </div> */}
                                                        <div className="form-group mt-1 ">

                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Defendant's Name"
                                                                required
                                                                className="form-control"
                                                                value={DefendentName}
                                                                onChange={e => setDefendentName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="number"
                                                                placeholder="Defendant's Phone No"
                                                                required
                                                                className="form-control"
                                                                value={DefendentPhoneNo}
                                                                onChange={e => setDefendentPhoneNo(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="email"
                                                                placeholder="Defendant's Email"
                                                                required
                                                                className="form-control"
                                                                value={DefendentEmail}
                                                                onChange={e => setDefendentEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-1 ">
                                                            <input
                                                                style={{ height: "35px" }}
                                                                type="text"
                                                                placeholder="Defendant's Address"
                                                                required
                                                                className="form-control"
                                                                value={DefendentAddress}
                                                                onChange={e => setDefendentAddress(e.target.value)}
                                                            />
                                                        </div>

                                                        {/* <p className="text-danger  pt-2">{ErrorMsg}</p> */}
                                                        <ul>

                                                            {
                                                                Files?.map((value: File, index: number) => {
                                                                    return <li key={index} style={{ textAlign: "left" }}> {index + 1}.) {value.name} </li>
                                                                })
                                                            }
                                                        </ul>
                                                        <label id="myLabel" htmlFor="Infinite">
                                                            <CustomButton
                                                                varient="outlined"
                                                                className="mt-1"
                                                                backgorundColor={basePurple}
                                                                textColor={basePurple}
                                                                width="100%"
                                                                height="30px"
                                                                hoverbackgroundColor={baseOrange}
                                                                styles={{ fontSize: "15px", fontWeight: "bold" }}
                                                                noFilter
                                                                onClick={() => document.getElementById("myLabel")?.click()}
                                                            >
                                                                Upload File +
                                                </CustomButton>
                                                        </label>
                                                        <input
                                                            onChange={(e: any) => {
                                                                if (e.target.files) {
                                                                    setFiles([...Files, e.target.files[0]])
                                                                }
                                                            }}
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            id="Infinite" />
                                                        {Error3 ? <p className="text-danger  pt-0   m-0">{Error3}</p> : null}
                                                        <CustomButton
                                                            varient="solid"
                                                            className="mt-1"
                                                            backgorundColor={basePurple}
                                                            width="100%"
                                                            height="30px"
                                                            hoverbackgroundColor={baseOrange}
                                                            styles={{ fontSize: "15px", fontWeight: "bold" }}
                                                            noFilter
                                                            onClick={async () => {
                                                                const res: any = await dispatch(Actions.DocumentOrder(Name, PhoneNo, Email, Address, DefendentName, DefendentEmail, DefendentPhoneNo, DefendentAddress, Files))
                                                                if (!res.Error) {
                                                                    // setSelectedTab(0)
                                                                    setName("")
                                                                    setPhoneNo("")
                                                                    setEmail("")
                                                                    setAddress("")
                                                                    setWhatToDeliever("Document")
                                                                    setDefendentName("")
                                                                    setDefendentPhoneNo("")
                                                                    setDefendentEmail("")
                                                                    setDefendentAddress("")
                                                                    setWhatToDeliever("")
                                                                    setFiles([])
                                                                    setSuccess3(res.Msg)
                                                                } else {
                                                                    setError3(res.Msg)

                                                                }
                                                            }}
                                                        >
                                                            Submit
                                                </CustomButton>
                                                    </div>

                                                )
                                            ) : null
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

    // return (
    //     <>
    //         {
    //             LogisiticsPage.map((value, index) => {
    //                 return <ServiceCard index={index} Data={{
    //                     Desc: value.Desc,
    //                     ImageALT: value.LargeHeading,
    //                     Img: value.Image_path,
    //                     LargeHeading: value.LargeHeading,
    //                     SmallHeading: value.SmallHeading,
    //                     ActionButtonAction: () => history.push("/estimate"),
    //                     ActionButtonText: "Book Now",
    //                 }} key={index} />
    //             })
    //         }

    //         <RequestPickupDialog
    //             open={IsRequestPickupOpen}
    //             closeDialog={() => setIsRequestPickupOpen(false)}
    //         />
    //     </>
    // )
}

export default LegalDelieveryForms

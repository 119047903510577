import React from 'react'
import Example from "../../assets/images/container-ship-596083_640.jpg"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from '../../store/root.reducer';

export const WorkingWithKenan: React.FC = () => {


    const dispatch = useDispatch();

    const WorkingWithkenan = useSelector((state: RootState) => state.Content.WorkingWithkenan)



    return (
        <div style={{ backgroundColor: "rgb(244, 244, 244)" }}>
            <div className="container pt-5  " >
                <h1 className="text-center pt-4 mb-4" style={{ fontWeight: "bold" }} >
                    Working With Kenan
            </h1>
                <div className="row mt-3 mb-5" >
                    {
                        WorkingWithkenan.map((value, index) => {
                            return (
                                <div key={value._id} className="col-md-4 col-sm-12 mt-4" >
                                    <div className="card d-flex justify-content-center align-items-center" style={{ border: "0" }} >
                                        <img style={{ borderRadius: "100%", height: "200px", width: "200px" }} src={value.Image_path} alt="Card image cap" />
                                        <div className="card-body text-center" >
                                            <h5 style={{ fontWeight: "bold" }} className="card-title">
                                                {value.LargeHeading}
                                            </h5>
                                            <p className="card-text">
                                                {value.Desc}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                        })
                    }
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { CustomButton } from '../../commonComponets'
import { baseOrange, basePurple } from '../../constants'
import { useHistory } from "react-router-dom"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { FlashAutoOutlined } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { GoogleMapsInterface, FromAddressToLatLong } from "../../interfaces/google.interface";
import Geocode from "react-geocode";
import BookNowDialog from './BookNowDialog';
// import distance from 'google-distance-matrix'
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../store/actions"
import GooglePlacesCommon from '../../commonComponets/GooglePlacesCommon';
import { RootState } from '../../store/root.reducer';



// distance.key("AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI")
Geocode.setApiKey("AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI");
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");

const EstimateScreen: React.FC = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [IsEstimateClicked, setIsEstimateClicked] = useState<boolean>(false)
    const [AddressFrom, setAddressFrom] = useState<any>("")
    const [AddressTo, setAddressTo] = useState<any>("")
    const [IsLoading, setIsLoading] = useState<boolean>(false)
    const [TotalDistance, setTotalDistance] = useState<number>(0)
    const [TotalFee, setTotalFee] = useState<number>(0)
    const [DelievryAmount, setDelievryAmount] = useState<number>(0)
    const [HSTCharge, setHSTCharge] = useState<number>(0)
    const [DriverTip, setDriverTip] = useState<number>(0)
    const [ErrorMsg, setErrorMsg] = useState<string>("")
    const [FromLatLong, setFromLatLong] = useState<any>({})
    const [ToLatLong, setToLatLong] = useState<any>({})
    const [TestingAddress, setTestingAddress] = useState<any>("")

    const [BookNowDialogOpen, setBookNowDialogOpen] = useState(false);
    const UserData = useSelector((state: RootState) => state.User.UserData)
    const Fees = useSelector((state: RootState) => state.Content.Fees?.Fees || "")

    useEffect(() => {
        if (UserData) {
            setAddressFrom(UserData?.Address || "")
        }
    }, [UserData])

    useEffect(() => {
        const Address = sessionStorage.getItem("@Addresses") ? JSON.parse(sessionStorage.getItem("@Addresses") || "{}") : null

        if (Address ) {
            setAddressFrom(Address.AddressFrom)
            setAddressTo(Address.AddressTo)
            getEstimate(Address.AddressFrom, Address.AddressTo)
        }
    }, [])


    async function getEstimate(address1?: string, address2?: string) {
        try {
            setIsLoading(true)
            if (!AddressFrom && !address1) throw "Please select an address to pickup from"
            if (!AddressTo && !address2) throw "Please select an address to dropoff"

            const res1: FromAddressToLatLong = await Geocode.fromAddress(AddressFrom || address1)
            const res2: FromAddressToLatLong = await Geocode.fromAddress(AddressTo || address2)

            const location1 = {
                lat: res1?.results[0].geometry?.location?.lat || 0,
                long: res1?.results[0].geometry?.location?.lng || 0,
            }
            const location2 = {
                lat: res2?.results[0].geometry?.location?.lat || 0,
                long: res2?.results[0].geometry?.location?.lng || 0,
            }

            setFromLatLong(location1)
            setToLatLong(location2)

            let origins = [`${location1.lat},${location1.long}`];

            let destinations = [`${location2.lat},${location2.long}`];

            const res: any = await dispatch(Actions.GetDistance(location1.lat, location1.long, location2.lat, location2.long));

            let distanceInKM = res?.distance?.value || 0;
            distanceInKM = distanceInKM * 0.001
            distanceInKM = parseFloat(distanceInKM.toFixed(1))
            setTotalDistance(parseFloat(distanceInKM.toFixed(1)));

            // if (distanceInKM > 20) {
            //     const above20 = distanceInKM - 20;
            //     const extraCost = above20 * parseFloat(Fees || '1.5');
            //     const roundedValue = parseFloat(extraCost.toFixed(2));

            //     setTotalFee(20 + roundedValue)
            // } else {
            //     setTotalFee(20)

            // }
            const extraCost = distanceInKM * parseFloat(Fees || '1.5');
            const roundedValue = parseFloat(extraCost.toFixed(2));
            const HST = (13 / 100) * roundedValue
            const total = parseFloat(HST.toFixed(2)) + roundedValue
            setDelievryAmount(roundedValue)
            setHSTCharge(parseFloat(HST.toFixed(2)));
            setDriverTip(0);
            setTotalFee(parseFloat(total.toFixed(2)))


            setIsLoading(false);
            setIsEstimateClicked(true)
            document.getElementById("TheEstimate")?.scrollIntoView({ behavior: "smooth", })

            sessionStorage.setItem("@Addresses", JSON.stringify({ AddressFrom: AddressFrom || address1, AddressTo: AddressTo || address2, }))

        } catch (err) {
            const errorMsgFromCatch: any = err
            setIsLoading(false)
            console.log(errorMsgFromCatch)
            setErrorMsg(errorMsgFromCatch || "")
        }
    }
    return (
        <>
            <div className="pricing-area section-padding pt-5 pb-5">
                <div className="container pt-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12">
                            <div className="s-section-title text-center mb-60">
                                <h2>ENTER YOUR PICK-UP &amp; DELIVERY LOCATIONS TO VIEW AN INSTANT ESTIMATE:</h2>
                                <p> </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-5">
                        <div className="col-lg-12">
                            <div className="pickup-form">
                                <div className="form-group">
                                    <h4>Pick-Up Address</h4>
                                    {/* <GooglePlacesAutocomplete
                                        apiKey="AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI"
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ["ca"],
                                            }
                                        }}
                                        selectProps={
                                            {
                                                escapeClearsValue: true,
                                                defaultOptions: {},
                                                onChange: setAddressFrom,
                                                value: AddressFrom,
                                                placeholder: 'Search an Area',
                                            }}
                                    /> */}
                                    <GooglePlacesCommon
                                        value={AddressFrom}
                                        setValue={e => setAddressFrom(e)}
                                        placeholder="Search an Area"
                                    />
                                </div>
                                <div className="form-group">
                                    <h4>Delivery Address</h4>
                                    {/* <GooglePlacesAutocomplete
                                        apiKey="AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI"

                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ["ca"],
                                            }
                                        }}
                                        selectProps={
                                            {

                                                escapeClearsValue: false,
                                                defaultOptions: {},
                                                isClearable: false,
                                                onChange: setAddressTo,
                                                value: AddressTo,

                                                placeholder: 'Search an Area',


                                            }}
                                    /> */}
                                    <GooglePlacesCommon
                                        value={AddressTo}
                                        setValue={e => setAddressTo(e)}
                                        placeholder="Search an Area"
                                    />
                                </div>
                                <p className="text-danger mt-4">{ErrorMsg}</p>
                                <div className="d-flex justify-content-end">
                                    {
                                        IsLoading ? (
                                            <CircularProgress className="mt-2" />
                                        ) : (
                                                <CustomButton
                                                    varient="outlined"
                                                    className="mt-2 px-5 "
                                                    backgorundColor={basePurple}
                                                    height="45px"
                                                    textColor={basePurple}
                                                    hoverTextColor="white"
                                                    hoverbackgroundColor={baseOrange}
                                                    styles={{ fontSize: "18px", fontWeight: "bold" }}
                                                    noFilter
                                                    onClick={() => getEstimate()}
                                                >
                                                    {IsEstimateClicked ? "Calculate again" : "Estimate"}
                                                </CustomButton>
                                            )
                                    }
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>

                    {IsEstimateClicked ? (
                        <>

                            <div className="pricing-estimated section-padding row " id="TheEstimate">
                                <div className="container">
                                    <div className="row justify-content-center pt-5">
                                        <div className=" pt-5">
                                            <div className="pt-5">
                                                <h3 className="text-capitalize">QUOTE</h3>
                                                <hr />
                                                <h4 style={{ fontWeight: "bold" }} >
                                                    Total Distance:
                                                    <span style={{ float: "right", color: baseOrange, }} >
                                                        {TotalDistance} Km
                                                    </span>
                                                </h4>
                                                <h4 style={{ fontWeight: "bold" }} className="pt-3" >
                                                    Delivery Charges:
                                                    <span style={{ float: "right", color: baseOrange, }}>
                                                        ${DelievryAmount}
                                                    </span>
                                                </h4>
                                                <h4 style={{ fontWeight: "bold" }} className="pt-3" >
                                                    HST Charges:
                                                    <span style={{ float: "right", color: baseOrange, }}>
                                                        ${HSTCharge}
                                                    </span>
                                                </h4>
                                                {/* <h4 style={{ fontWeight: "bold" }} className="pt-3" >
                                                    Driver Tip:
                                                    <span style={{ float: "right", color: baseOrange, }}>
                                                        ${DriverTip}
                                                    </span>
                                                </h4> */}
                                                <h4 style={{ fontWeight: "bold" }} className="pt-3" >
                                                    Total Amount:
                                                    <span style={{ float: "right", color: baseOrange, }}>
                                                        ${TotalFee}
                                                    </span>
                                                </h4>

                                                <p
                                                    className="pt-3"
                                                    style={{ lineHeight: "26px", fontSize: "16px", fontWeight: 550 }}
                                                >
                                                    Note: Pick-up &amp; delivery prices are estimates only and do not reflect variations due to discounts, traffic delays, or other factors. Flat rates and minimum fees may apply.
                                                </p>
                                                <div className="d-flex justify-content-end">
                                                    <CustomButton
                                                        varient="outlined"
                                                        height={"45px"}
                                                        width={"35%"}
                                                        backgorundColor={basePurple}
                                                        textColor={basePurple}
                                                        hoverTextColor="white"
                                                        hoverbackgroundColor={baseOrange}
                                                        styles={{ fontSize: "18px" }}
                                                        children={"BOOK NOW"}
                                                        onClick={() => history.push("/signup?redirect=true")}
                                                    />
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <BookNowDialog
                                open={BookNowDialogOpen}
                                closeDialog={() => setBookNowDialogOpen(false)}
                                RecieversAddress={AddressTo}
                                SendersAddress={AddressFrom}
                                TotalDistance={TotalDistance}
                                TotalFee={TotalFee}
                                FromLatLong={FromLatLong}
                                ToLatLong={ToLatLong}
                            /> */}
                        </>
                    ) : ("")
                    }
                    {/* 
                    <div className="row justify-content-center pt-5">
                        <div className="col-xl-10 col-lg-12 pt-5">
                            <div className="s-section-title text-center mt-5">
                                <h2>PRICING</h2>
                                <p> </p>
                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center pt-5">
                        <div className="col-xl-4 offset-xl-1 text-center">
                            <div className="mentor-price-table">
                                <div className="mentor-price-table__header">
                                    <h3 className="mentor-price-table__heading">FLAT RATE</h3>
                                </div>
                                <div className="">
                                    <h3 style={{ fontWeight: "bold", fontSize: "40px", color: "#555" }}>
                                        <small style={{ fontWeight: "bold", fontSize: "20px" }}>$</small> 20
                            </h3>
                                    <h3 style={{ fontSize: "13px" }} className="pb-4 pt-1">PER DELIVERY</h3>
                                </div>
                                <ul style={{ listStyle: "none" }}>
                                    <li><strong>Within 20km</strong></li>
                                    <li>Up to 75 lbs</li>
                                    <li>Live-tracking</li>
                                    <li>SMS status updates</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 offset-xl-1 align-self-center text-center">
                            <div className="mentor-price-table">
                                <div className="mentor-price-table__header">
                                    <h3 className="mentor-price-table__heading">EXCEEDING 20KM?</h3>
                                </div>
                                <div className="">
                                    <h3 style={{ fontWeight: "bold", fontSize: "40px", color: "#555" }}>
                                        <small style={{ fontWeight: "bold", fontSize: "20px" }}>$</small> 1.5
                            </h3>
                                    <h3 style={{ fontSize: "13px" }} className="pb-4 pt-1">PER KILOMETER</h3>
                                </div>
                                <ul style={{ listStyle: "none" }}>
                                    <li><strong>Flat Rate + $1.5 per km</strong></li>

                                </ul>
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>
        </>
    )
}

export default EstimateScreen

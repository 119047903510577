import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import EstimateScreen from '../componets/estimate/EstimateScreen'

const Estimate:React.FC = () => {

    useEffect(()=>{
        document.title = "Estimate | Proark - Earn Money Driving / Courier With Us Now |"
    },[])
    return (
        <>
            <Banner title="Estimation" />
            <EstimateScreen/>
        </>
    )
}

export default Estimate

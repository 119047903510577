import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import BecomeRiderForm from '../componets/becomeRider/BecomeRiderForm'
import ReadyToRide from '../componets/home/ReadyToRide'
import TEMP from "../componets/becomeRider/TEMP"
const BecomeRider = () => {

    useEffect(()=>{
        document.title = "Become Driver | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <div style={{ background: "rgb(244, 244, 244)" }}>
            <Banner title="Become a Driver" />
            {/* <BecomeRiderForm  /> */}
            <div className="py-5">
                <h3 className="text-center">
                    Download the Proark App today
                </h3>
            </div>
            <TEMP />
            {/* <ReadyToRide /> */}
        </div>
    )
}

export default BecomeRider

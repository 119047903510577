import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import ContinueWithKenanForm from '../componets/continueWithKenan/ContiueWithKenanForm'
// import LoginForm from '../componets/login/LoginForm'

const ContinueWithKenan: React.FC = (props) => {


    useEffect(()=>{
        document.title = "Continue With Proark | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <div style={{background:"rgb(244, 244, 244)"}}>
            <Banner title="Continue with phone no." />
          <ContinueWithKenanForm/>
        </div>
    )
}

export default ContinueWithKenan

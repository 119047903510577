import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import LogistiscScreen from '../componets/logistics/LogistiscScreen'

const Logistics = () => {


  useEffect(()=>{
    document.title = "Logistics | Proark - Earn Money Driving / Courier With Us Now |"
},[])

    return (
        <>
          <Banner title="Logistics" />
          <LogistiscScreen/>
        </>
    )
}

export default Logistics

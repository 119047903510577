import React from 'react'
import { Link } from 'react-router-dom'
import { ServiceCardPropsInterface } from '../interfaces'
import { ArrowForward } from "@material-ui/icons"
import { basePurple } from '../constants'
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from '../store/root.reducer'
import parse from 'html-react-parser';

const ServiceCard: React.FC<ServiceCardPropsInterface> = (props) => {

    //? props.index % 2 == 0 ? "rgb(244, 244, 244) !important" : "white !important" : ""
    const history = useHistory()

    const IsAuth = useSelector((state: RootState) => state.User.IsLogin)

    return (
        <section className={`serviceSection ${props.index % 2 == 0 ? "" : "invert"}`} style={{ backgroundColor: props.isHome ? props.index % 2 == 0 ? "rgb(244, 244, 244)" : "white" : "" }} >
            <div className="serviceDivContainer">
                <div
                    style={{
                        background: `url(${props.Data.Img})`,
                        backgroundSize: "cover"
                    }}
                    className="serviceDivLeft"
                />


                <div className="serviceDivRight">
                    <span className="serviceSmallHeading">
                        {props.Data.SmallHeading}
                    </span>
                    <h3 className="serviceH3">
                        {
                            props.Data.LargeHeading
                        }
                    </h3>
                    <p className="servicePara">
                        {
                            parse(props.Data.Desc || "")
                        }
                    </p>
                    {
                        props.isLogistics ? null : (
                            <a className="bookNowAnchor" onClick={() => {
                                if (props.Data.ActionButtonAction) return props.Data.ActionButtonAction();


                                if (IsAuth) return history.push("/bookings")
                                history.push("/signup?redirect=true")

                            }}>
                                {props.Data.ActionButtonText || "Book Now"} <ArrowForward className="arrowBookNow" />
                            </a>
                        )
                    }

                    {
                        props.Data.ActionButtonText2 ? (
                            <a className="bookNowAnchor ml-3    " onClick={() => {
                                if (props.Data.ActionButtonAction2) return props.Data.ActionButtonAction2();
                                history.push("/processServing")
                            }}>
                                {props.Data.ActionButtonText2 || "Book Now"} <ArrowForward className="arrowBookNow" />
                            </a>
                        ) : null
                    }
                </div>
            </div>
        </section>
    )
}

export default ServiceCard

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../root.reducer';
import { UserDataObjInterface, UserState } from "../../interfaces/"


const initialState: UserState = {
    IsLogin: localStorage.getItem("@Token") ? true : false,
    UserData: null,
    Token: localStorage.getItem("@Token")
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        LoginSuccess: (state, action: PayloadAction<{ UserData: UserDataObjInterface, Token: string }>) => {
            state.IsLogin = true;
            state.Token = action.payload.Token;
            state.UserData = action.payload.UserData
        },

        Logout: (state, action: PayloadAction) => {
            state.IsLogin = false;
            state.UserData = null;
            state.Token = ""
                localStorage.removeItem("@Token")

        },

        VerifyToken: (state, action: PayloadAction<{ Token: string, UserData: UserDataObjInterface }>) => {
            state.Token = action.payload.Token
            state.UserData = action.payload.UserData
            localStorage.setItem("@Token", action.payload.Token)
        },
        UpdateUserProfile: (state, action: PayloadAction<{ UserData: UserDataObjInterface }>) => {
            state.UserData = action.payload.UserData
        }
    },
});

export const { LoginSuccess, Logout, VerifyToken,UpdateUserProfile } = userSlice.actions;

export default userSlice.reducer;


import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import { Link } from 'react-router-dom'
import { CustomButton } from "../../commonComponets"
import { basePurple, baseOrange } from '../../constants'



const Floater = () => {

    const location = useLocation()
    const [ShowCookiesBar,setShowCookiesBar] = useState(true)


    useEffect(()=>{
        if(localStorage.getItem("acceptedCookie")){
            setShowCookiesBar(false)
        }
    },[])

    if (location.pathname.split("/").includes("admin")) return <></>



    if (!localStorage.getItem("acceptedCookie") && ShowCookiesBar) return (
        <>
            <div className="FloaterCookies ">
                <div className="PDIV" >
                    <p style={{ marginBottom: "0" }}>
                    We use cookies on this website to improve our site and your user experience. Please refer to the section on Cookies within our <Link style={{color:baseOrange}} to="/privacyPolicy">Privacy Policy</Link> for more information. 
                    </p>
                </div>
                <div className="ButtonDiv" >
                    <CustomButton
                        backgorundColor={"white"}
                        textColor={basePurple}
                        varient="solid"
                        height={"40px"}
                        width={"200px"}
                        styles={{ fontSize: "14px", fontWeight: "bold" }}
                        children={"ACCEPT ALL COOKIES"}
                        className="myButtonForHeader"
                        onClick={() =>{
                            localStorage.setItem("acceptedCookie","true")
                            setShowCookiesBar(false)
                        }}
                    />
                </div>
            </div>
        </>
    )
    return <></>
    return (
        <div className="Floater">
            <div className="TheContainer">
                <Link to="/signup" className="footerContactLink2" >Courier with us</Link>
            </div>
        </div>
    )
}

export default Floater

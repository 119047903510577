import React from 'react'
import Example from "../../assets/images/container-ship-596083_640.jpg"
import { useRealTimeDeviceDetector } from '../../helpers/useRealTimeDeviceDetector'
import { useDispatch, useSelector } from "react-redux"
import { RootState } from '../../store/root.reducer';
import parse from 'html-react-parser';

export const RequirementsScreen: React.FC = () => {

    const { IsMob, IsTab } = useRealTimeDeviceDetector()
    const dispatch = useDispatch();

    const Requirements = useSelector((state: RootState) => state.Content.Requirements)


    return (
        <div className="pricing-area section-padding pt-5 pb-5">
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12">
                        <div className="s-section-title text-center mb-5">
                            <h1 style={{ fontWeight: "bold" }} >What you need to drive with Kenan</h1>
                            <p> Once you’re approved, you’ll also be able to drive anywhere in Canada!</p>
                        </div>
                    </div>
                </div>

                {
                    Requirements.map((value, index) => {
                        return (
                            <div key={value._id} className="row mt-5" style={{ borderBottom: "1px solid #c9c9c9" }}  >
                                <div className="col-md-4 col-sm-12 text-center" >
                                    <img src={value.Image_path} style={{ height: "200px", width: "200px", borderRadius: "100%", marginBottom: "30px" }} />
                                </div>
                                <div className="col-md-8 col-sm-12" >
                                    <div className="ListContent pb-4">
                                        <h3 className="listSubHeading mt-4" style={{ fontWeight: "bold", textAlign: IsMob ? "center" : "left" }} >
                                            {value.LargeHeading}
                                        </h3>
                                        <div className="pl-5 pt-3" >
                                            <ul className="mainList">
                                                {
                                                    parse(value?.Desc.replace(/\"<li>/g,`<li class="conatact-list-item">`) || "")
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

import { styled } from '@material-ui/styles'
import React, { useState } from 'react'
import { CustomButton } from '../../commonComponets'
import { baseOrange, basePurple } from '../../constants'
import Styled from "styled-components";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import { RootState } from "../../store/root.reducer"
import { validate } from 'email-validator';
import { CircularProgress } from '@material-ui/core';
import * as Actions from "../../store/actions"
import { toast } from "react-toastify"

const StyledAnchor = Styled.a`
    color:${basePurple};

    &:hover{
        color:${baseOrange};
        cursor:pointer;
    }
`

const ForgotPasswordScreen = () => {


    const dispatch = useDispatch()
    const history = useHistory();

    const [IsSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [IsLoading, setIsLoading] = useState<boolean>(false)
    const [Email, setEmail] = useState<string>("")
    const [Code, setCode] = useState<string>("")
    const [NewPass, setNewPass] = useState<string>("")
    const [ConfirmPass, setConfirmPass] = useState<string>("")
    const [ErrorMsg, setErrorMsg] = useState<string>("")
    const [Step, setStep] = useState<number>(1)




    async function submitEmail(isReset?: boolean): Promise<void> {

        try {
            setIsLoading(true)
            setErrorMsg("")
            if (!Email) throw "Please enter an email address!"
            if (!validate(Email)) throw "Please enter a valid email address!"

            const res = await dispatch(Actions.RequestResetPassword(Email))
            if (res) throw res;
            setIsLoading(false)
            if (isReset) {
                toast.success("Code has been resent to your email address")

            }
            setStep(2)


        } catch (error) {
            console.log(error)
            setErrorMsg(error)
            setIsLoading(false)
        }

    }

    async function resetPassword(): Promise<void> {
        try {
            setIsLoading(true)
            setErrorMsg("")
            if (!Code) throw `Please enter the code sent to your email address: ${Email}!`
            if (!NewPass) throw `Please enter a new password!`
            if (NewPass !== ConfirmPass) throw `New password and confirm password does not match!`

            const res = await dispatch(Actions.ResetPassword(Email, Code, NewPass))

            if (res) throw res;

            setIsLoading(false)

            toast.success("Your password has been reset, Please login using the new password")

            history.push("/login")

        } catch (error) {
            console.log(error)
            setErrorMsg(error)
            setIsLoading(false)
        }

    }



    if (Step === 2) {
        return (
            <>
                <div className="container login-form mt-5 mb-5">
                    <div className="row display-flex justify-content-center mt-5">
                        <div className="col-lg-8 text-center mt-5">
                            <h1>Forgot Password</h1>
                            <p style={{ color: "grey" }}>
                                A code has been sent to your email address: {Email}
                            </p>
                            <div className="form-group mt-4 ">
                                <input
                                    // autoComplete="new-password"

                                    style={{ height: "54px" }}
                                    type="password"
                                    placeholder="Enter Code *"
                                    required
                                    className="form-control"
                                    value={Code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <input
                                    // autoComplete="new-password"
                                    style={{ height: "54px" }}
                                    type="password"
                                    placeholder="Enter New Password *"
                                    required
                                    className="form-control mt-4"
                                    value={NewPass}
                                    onChange={(e) => setNewPass(e.target.value)}
                                />
                                <input
                                    // autoComplete="new-password"

                                    style={{ height: "54px" }}
                                    type="password"
                                    placeholder="Confirm Password *"
                                    required
                                    className="form-control mt-4 mb-4"
                                    value={ConfirmPass}
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                />
                                <p className="text-danger pt-4">{ErrorMsg}</p>

                                {
                                    IsLoading ? <CircularProgress /> : (
                                        <>
                                            <CustomButton
                                                varient="solid"
                                                backgorundColor={basePurple}
                                                width="100%"
                                                height="50px"
                                                hoverbackgroundColor={baseOrange}
                                                styles={{ fontSize: "18px", fontWeight: "bold" }}
                                                noFilter
                                                onClick={() => resetPassword()}
                                            >
                                                SUBMIT
                                             </CustomButton>
                                            <p className="mt-4  mb-5">
                                                <StyledAnchor
                                                    onClick={() => setStep(1)}
                                                >
                                                    Go Back
                                                </StyledAnchor> {" "} |{" "}
                                                <StyledAnchor
                                                    onClick={() => submitEmail(true)}
                                                >
                                                    Resend Code
                                                </StyledAnchor>
                                            </p>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="container login-form mt-5 mb-5">
                <div className="row display-flex justify-content-center mt-5">
                    <div className="col-lg-8 text-center mt-5">
                        <h1>Forgot Password</h1>
                        <div className="form-group mt-5 ">
                            <input
                                style={{ height: "54px" }}
                                type="email"
                                placeholder="Enter Email *"
                                required
                                className="form-control"
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <p className="text-danger pt-4">{ErrorMsg}</p>
                            {
                                IsLoading ? <CircularProgress /> : (
                                    <CustomButton
                                        varient="solid"
                                        className="mt-2"
                                        backgorundColor={basePurple}
                                        width="100%"
                                        height="50px"
                                        hoverbackgroundColor={baseOrange}
                                        styles={{ fontSize: "18px", fontWeight: "bold" }}
                                        noFilter
                                        onClick={() => submitEmail()}
                                    >
                                        SUBMIT
                                    </CustomButton>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordScreen

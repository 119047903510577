import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CustomButton } from '../../commonComponets'
import { baseOrange, basePurple } from '../../constants'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import HomeIcon from '@material-ui/icons/Home';
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../store/actions"
import { BookingObjINterface } from '../../interfaces';

const TrackingScreen: React.FC = () => {

    const dispatch = useDispatch()

    const [ShowStatus, setShowStatus] = useState(false)
    const [TrackingID, setTrackingID] = useState("")
    const [IsLoading, setIsLoading] = useState(false)
    const [Msg, setMsg] = useState("")
    const [Status, setStatus] = useState(1)


    async function getStatus() {
        if (!TrackingID) return toast.error("Please enter a tracking ID")

        setIsLoading(true)

        const res: any = await dispatch(Actions.GetOneBookings(TrackingID));

        setIsLoading(false)

        if (res.Error) {
            toast.error("Invalid tracking ID provided!")
            return;
        }
        if (res.Bookings.length == 0) {
            toast.error("Invalid tracking ID provided!")
            return;
        }
        setShowStatus(true)
        const booking: BookingObjINterface = res.Bookings[0]
        const bookingMsg = booking.Status
        if (bookingMsg == "In Progress") {
            setStatus(1)
            setMsg("Your order is in progress! We will update the status shortly")
            return;
        }
        if (bookingMsg == "Picked up") {
            setStatus(2)
            setMsg("Your order has been Picked up")
            return;
        }
        if (bookingMsg == "Delivered") {
            setStatus(3)
            setMsg("Your order has been Delivered")
            return;
        }
     
        if (bookingMsg == "Successful") {
            setStatus(4)
            setMsg("Order complete")
            return;
        }
    }

    return (
        <>
            <div className="tracking-area pt-5 pb-5">
                <div className="container pt-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="tracking-id-info text-center">
                                <p>Enter your courier number to see where your parcel is!
                                    {/* <Link to="/" style={{fontWeight:600,fontStyle:"italic",color:baseOrange}} >Order Number.</Link> */}
                                </p>
                                <div className="d-flex justify-content-center pt-4 text-center row">
                                    <input
                                        className="col-lg-6 p-2 m-1"
                                        type="text"
                                        placeholder="Order id"
                                        value={TrackingID}
                                        onChange={e => setTrackingID(e.target.value)}
                                    />
                                    &nbsp;
                                    <CustomButton
                                        varient="solid"
                                        className=" col-lg-3 m-sm-1 p-2"
                                        backgorundColor={basePurple}
                                        width="98%"
                                        height="45px"
                                        hoverbackgroundColor={baseOrange}
                                        styles={{ fontSize: "18px", fontWeight: "bold" }}
                                        noFilter
                                        onClick={() => {
                                            //toast.error("Invalid tracking ID!")
                                            if (IsLoading) return
                                            getStatus()
                                        }}
                                    >
                                        {IsLoading ? "LOADING" : "TRACK"}
                                    </CustomButton>
                                    <h2 className="mt-3">{Msg}</h2>

                                </div>
                                {
                                    ShowStatus ? (
                                        <>
                                            <div className="tracking-list">
                                                <div className="ULContainer">
                                                    <hr className="myHr" />
                                                    <ul style={{ listStyle: "none" }}>
                                                        <li className={`${Status == 1 ? "active" : ""}`}>
                                                            <div className="tracking-list-icon">
                                                                <LocalShippingIcon style={{ fontSize: "32px" }} />
                                                            </div>
                                                            <div className="tracking-list-content">
                                                                <p>In Progress</p>
                                                            </div>
                                                        </li>
                                                        <li className={`${Status == 2 ? "active" : ""}`}>
                                                            <div className="tracking-list-icon">
                                                                <HomeIcon style={{ fontSize: "32px" }} />
                                                            </div>
                                                            <div className="tracking-list-content">
                                                                <p>Picked up</p>
                                                            </div>
                                                        </li>
                                                        <li className={`${Status == 3 ? "active" : ""}`}>
                                                            <div className="tracking-list-icon">
                                                                <LocationOnIcon style={{ fontSize: "32px" }} />
                                                            </div>
                                                            <div className="tracking-list-content">
                                                                <p>Delivered</p>
                                                            </div>
                                                        </li>
                                                        {/* <li className={`${Status == 4 ? "active" : ""}`}>
                                                            <div className="tracking-list-icon">
                                                                <CheckBoxOutlinedIcon style={{ fontSize: "32px" }} />
                                                            </div>
                                                            <div className="tracking-list-content">
                                                                <p>Successful</p>
                                                            </div>
                                                        </li> */}
                                                    </ul>

                                                </div>
                                            </div>

                                        </>

                                    ) : null
                                }
                                <div className="pb-5 pt-5">
                                    <p style={{ fontWeight: 600, fontStyle: "bold" }} >
                                        <Link to="/contactUs" style={{ color: baseOrange }} >NEED HELP ?</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrackingScreen

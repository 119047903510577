// import { Link } from '@material-ui/icons'
import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { baseOrange, basePurple } from '../../constants'
import { CustomButton } from '../../commonComponets'
import { useSelector, useDispatch } from "react-redux"
import { RootState } from '../../store/root.reducer'
import ContactUsScreen from './ContactUsScreen'

const ContactUsForm = () => {

    const [IsFormClicked, setIsFormClicked] = useState(false)
    return (
        <>
            {
                IsFormClicked ? <ContactUsScreen /> : (
                    <div className="need-help mt-5 pt-5 pb-5 text-center" >
                        <h3 style={{ fontWeight: "bold" }}>
                            Still need help ?
                    </h3>
                        <CustomButton
                            varient="solid"
                            className=" col-lg-3 mt-2 px-4"
                            backgorundColor={basePurple}
                            height="45px"
                            hoverbackgroundColor={baseOrange}
                            styles={{ fontSize: "18px", fontWeight: "bold" }}
                            noFilter
                            onClick={() => setIsFormClicked(true)}
                        >
                            Contact Us
                    </CustomButton>
                    </div>
                )
            }
        </>
    )
}

export default ContactUsForm

import React, { useEffect, useState } from 'react'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../../store/actions"
import { RootState } from '../../store/root.reducer';
import parse from 'html-react-parser';
import { CircularProgress } from '@material-ui/core';
import { useParams } from "react-router-dom"
import { ParaResponse, ParaInterface } from '../../interfaces';
import { Link } from "react-router-dom"
import { baseOrange, basePurple } from '../../constants'
import {useRealTimeDeviceDetector} from "../../helpers/useRealTimeDeviceDetector"
const HelpScreen: React.FC = () => {

    const dispatch = useDispatch()
    const {IsMob} = useRealTimeDeviceDetector()
    const { id } = useParams<{ id: string }>()

    const [Data, setData] = useState<any>()
    const HeadingsAndList = useSelector((state: RootState) => state.Content.HeadingsAndListing)

    useEffect(() => {
        getData()
    }, [id])

    async function getData() {
        try {
            const res = await dispatch(Actions.GettingPara(id))
            setData(res)
        } catch (error) {
            setData({})
        }
    }
    if (Data == null) return (
        <div style={{ width: "100%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </div>
    )

    return (
        <>
            <div style={{ width: "100%", display: "flex",flexDirection: IsMob ?  "column-reverse":"row",paddingLeft:"10%",paddingRight:"10%"}}>
                <aside style={{ width: IsMob ? "100%" :"300px", borderRight: "1px solid #ccc", padding: "10px" }} className="pt-5">
                    {
                        HeadingsAndList?.map((value, index) => {
                            return (
                                <div key={value._id} className="ListContent pb-4" style={{ borderBottom: "1px solid #c9c9c9" }} >
                                    <h3 className="listSubHeading mt-4" style={{ fontWeight: "bold", fontSize: "20px" }} >
                                        {value.Heading}
                                    </h3>
                                    <div className="pl-5 pt-3" >
                                        <ul className="mainList">

                                            {
                                                value.Children?.map((value2, index2) => {
                                                    return (
                                                        <li key={value2._id} className={`conatact-list-item ${value2.ListItemUniqueID === id ? "conatact-list-item-Active" : ""}`} >
                                                            <Link className="cont-list-link" to={`/help/${value2.ListItemUniqueID}`} style={{ color:value2.ListItemUniqueID === id ? basePurple : "black", fontSize: "18px" ,fontWeight:value2.ListItemUniqueID === id ? "bold" : "normal",}} >
                                                                {value2.ListItem}
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>

                                </div>

                            )
                        })
                    }
                </aside>
                <div style={{ flex: 1 }}>
                    {
                        Data?.Text ? (
                            <div className="container pt-5 pb-5 mb-5">
                                <h2 className="text-left pt-4 mb-5" >
                                    <ArrowRightIcon /> {Data?.ListItemUniqueID.slice(0, -8).replace(/\-/g, " ")}
                                </h2>

                                <hr className="pb-4" />
                                {parse(Data?.Text || "")}
                            </div>
                        ) : (
                                <div className="container pt-5 pb-5 mb-5">
                                    <h2 className="text-center pt-4 mb-5" >
                                        No Page Found
                                    </h2>

                                </div>
                            )
                    }
                </div>
            </div>
        </>
    )
}

export default HelpScreen

import React, { useEffect } from 'react'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../../store/actions"
import { RootState } from '../../store/root.reducer';
import parse from 'html-react-parser';

const TermsAndConditionsScreen: React.FC = () => {


    const dispatch = useDispatch()


    const Policy = useSelector((state: RootState) => state.Content.Policy)

    useEffect(() => {
        dispatch(Actions.GetPolicy("Terms"))
    }, [])

    return (
        <>
            <div className="container pt-5 pb-5 mb-5">
                <h2 className="text-center pt-4 mb-5" >
                <ArrowRightIcon /> { Policy.Heading }
                </h2>

                <hr className="pb-4" />
                {
                    parse(Policy?.Text || "")
                }
            </div>
        </>
    )
}

export default TermsAndConditionsScreen

import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import TrackingScreen from '../componets/tracking/TrackingScreen'

const Tracking:React.FC = () => {


    useEffect(()=>{
        document.title = "Tracking | Proark - Earn Money Driving / Courier With Us Now |"
    },[])

    return (
        <>
            <Banner title="Tracking"/>
            <TrackingScreen/>
        </>
    )
}

export default Tracking

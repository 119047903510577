import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import bookingReducer from './reducers/booking.reducer';
import contentReducer from './reducers/content.reducer';
import UserReducer from "./reducers/user.reducer"
export const store = configureStore({
  reducer: {
    User: UserReducer,
    Booking: bookingReducer,
    Content: contentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import React, { lazy, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import { Loader } from "./commonComponets/"
// import AdminWrapper from './pages/admin/AdminWrapper';
import { useDispatch } from "react-redux"
import * as Actions from "./store/actions"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Home from "./pages/Home"
import LoginScreen from "./pages/Login"
import SignUpScreen from "./pages/SignUp"
import ForgotPasswordScreen from "./pages/ForgotPassword"
import ServicesScreen from "./pages/Services"
import ContactUsScreen from "./pages/ContactUs"
import TermsAndConditionsScreen from "./pages/TermsAndConditions"
import PrivacyPolicyScreen from "./pages/PrivacyPolicy"
import CookiePolicyScreen from "./pages/CookiePolicy"
import EstimateScreen from "./pages/Estimate"
import AboutUsScreen from "./pages/AboutUs"
import BookingsScreen from "./pages/Booking"
import BookingsScreen2 from "./pages/Bookings"
import TrackingScreen from "./pages/Tracking"
import LegalDelievery from "./pages/LegalDelievery"
import Logistics from "./pages/Logistics"
import Payment from "./pages/Payment"
import SelectRiderSimple from "./pages/SelectRider"
import DriveWithMe from "./pages/DriveWithMe";
import Help from './pages/Help';
import BecomeRider from './pages/BecomeRider';
import NavBarWeb from "./componets/navbar/NavBarWeb"
import Footer from "./componets/footer/Footer"
import Floater from './componets/floater/Floater';
import Blogs from './pages/Blogs';
import BlogDetails from './pages/BlogDetails';
import { Requirements } from './pages/Requirements';
import { Careers } from './pages/Careers';
import careerDetail from './pages/CareerDetail';
import ContinueWithKenan from './pages/ContinueWithKenan';


const Home = lazy(() => import("./pages/Home"))
const AdminWrapper = lazy(() => import('./pages/admin/AdminWrapper'));


// const LoginScreen = lazy(() => import("./pages/Login"))
// const SignUpScreen = lazy(() => import("./pages/SignUp"))
// const ForgotPasswordScreen = lazy(() => import("./pages/ForgotPassword"))
// const ServicesScreen = lazy(() => import("./pages/Services"))
// const ContactUsScreen = lazy(() => import("./pages/ContactUs"))
// const TermsAndConditionsScreen = lazy(() => import("./pages/TermsAndConditions"))
// const PrivacyPolicyScreen = lazy(() => import("./pages/PrivacyPolicy"))
// const CookiePolicyScreen = lazy(() => import("./pages/CookiePolicy"))
// const EstimateScreen = lazy(() => import("./pages/Estimate"))
// const AboutUsScreen = lazy(() => import("./pages/AboutUs"))
// const BookingsScreen = lazy(() => import("./pages/Booking"))
// const BookingsScreen2 = lazy(() => import("./pages/Bookings"))
// const TrackingScreen = lazy(() => import("./pages/Tracking"))
// const LegalDelievery = lazy(() => import("./pages/LegalDelievery"))
// const Logistics = lazy(() => import("./pages/Logistics"))
// const Payment = lazy(() => import("./pages/Payment"))
// const SelectRiderSimple = lazy(() => import("./pages/SelectRider"))
// const DriveWithMe = lazy(() => import("./pages/DriveWithMe"));
// const Help = lazy(() => import('./pages/Help'));
// const BecomeRider = lazy(() => import('./pages/BecomeRider'));
// const NavBarWeb = lazy(() => import("./componets/navbar/NavBarWeb"))
// const Footer = lazy(() => import("./componets/footer/Footer"))

const App: React.FC = () => {

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    window.scroll(0, 0)
  }, [location.pathname])

  useEffect(() => {
    dispatch(Actions.GetFees())
    dispatch(Actions.getNavBarContent())
    dispatch(Actions.VerifyTokenAction())
    dispatch(Actions.GetHomeHeadings())
    dispatch(Actions.GetHomeBanner())
    dispatch(Actions.GetHomeServices())
    dispatch(Actions.GetAboutUsNewData())
    dispatch(Actions.getHomeSmartHeadings())
    dispatch(Actions.GetLogistics())
    dispatch(Actions.GetDriverSignUp())
    dispatch(Actions.GetBookingsList())
    dispatch(Actions.GetDriveWithMeData())
    dispatch(Actions.PageTitleAndImage())
    dispatch(Actions.GettingPageHeadingsAndTitles())
    dispatch(Actions.GetNewBlogData())
    dispatch(Actions.GetPricingData())
    // dispatch(Actions.GetOurLocations())
    dispatch(Actions.GetWorkingWithkenan())
    dispatch(Actions.GetOurTeam())
    dispatch(Actions.GetRequirements())
    dispatch(Actions.GetContactUs())
    dispatch(Actions.getFooterSectionHeadingRequest())
    dispatch(Actions.getFooterStaticContent())
    

    


    
  }, [])

  // return <Loader />
  return (
    <Suspense fallback={
      <Loader />
    }>
      <ToastContainer position="top-center" style={{ zIndex: 1000000000000 }} />
      <NavBarWeb />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/becomeDriver" component={BecomeRider} />
        <Route path="/login" exact component={LoginScreen} />
        <Route path="/continueWithProark" exact component={ContinueWithKenan} />
        <Route path="/signup" exact component={SignUpScreen} />
        <Route path="/forgotpassword" exact component={ForgotPasswordScreen} />
        <Route path="/services" exact component={ServicesScreen} />
        <Route path="/logistics" exact component={Logistics} />
        <Route path="/processServing" exact component={LegalDelievery} />
        <Route path="/contactUs" exact component={ContactUsScreen} />
        <Route path="/help/:id" exact component={Help} />
        <Route path="/blogs" exact component={Blogs} />
        <Route path="/blogs/:id" exact component={BlogDetails} />
        <Route path="/termsAndConditions" exact component={TermsAndConditionsScreen} />
        <Route path="/privacyPolicy" exact component={PrivacyPolicyScreen} />
        <Route path="/cookiePolicy" exact component={CookiePolicyScreen} />
        <Route path="/estimate" exact component={EstimateScreen} />
        <Route path="/courier-with-us" exact component={DriveWithMe} />
        <Route path="/drive-with-us" exact component={DriveWithMe} />
        <Route path="/aboutUs" exact component={AboutUsScreen} />
        <Route path="/requirements" exact component={Requirements} />
        <Route path="/careers" exact component={Careers} />
        <Route path="/Careers/:id" exact component={careerDetail} />
        <Route path="/bookings" exact component={BookingsScreen2} />
        <Route path="/tracking" exact component={TrackingScreen} />
        <Route path="/payment" exact component={Payment} />
        <Route path="/bookingDetails/:id" exact component={SelectRiderSimple} />
        <Route path="/admin/:adminPath" exact component={AdminWrapper} />
        <Route path="/admin/:adminPath/:ID" exact component={AdminWrapper} />
        <Route path="/admin" exact render={() => <Redirect to="/admin/user" />} />
        <Route path="*" exact render={() => <Redirect to="/" />} />
      </Switch>
      <Footer />
      <Floater/>

    </Suspense>
  )
}

export default App;

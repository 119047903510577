import React, { useEffect, useState } from 'react'
import { CustomButton } from '../../../commonComponets'
import { baseOrange, basePurple, SquareupScriptLink, } from '../../../constants'
import { Redirect, useHistory, useLocation } from "react-router-dom"
import PaymentForm from './PaymentForm'
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/root.reducer"
declare global {
    interface Window { SqPaymentForm: any; }
}


const Payments: React.FC = () => {

    const history = useHistory()
    const location = useLocation()

    const [HasLoaded, setHasLoaded] = useState(false);


    const onGoingBooking = useSelector((state: RootState) => state.Booking.OnGoingBookning)


    useEffect(() => {

        let sqPaymentScript = document.createElement("script");
        sqPaymentScript.src = SquareupScriptLink;
        sqPaymentScript.type = "text/javascript";
        sqPaymentScript.async = false;
        sqPaymentScript.onload = () => {
            setHasLoaded(true);
        };
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    });


    if (location.pathname.split("/").includes("admin") && !onGoingBooking) return <Redirect to="/admin/user" />
    if (!onGoingBooking) return <Redirect to="/" />

    return !HasLoaded ? <><div style={{ minHeight: "500px" }}>Loading...</div></> : <PaymentForm paymentForm={window.SqPaymentForm} />
}

export default Payments

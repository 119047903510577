import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../root.reducer'
import {
  BookingObjINterface,
  BookingState,
  ContentInterface,
  contentState,
  GetBookingResponse,
  UserDataObjInterface,
  UserState,
  PolicyInterface,
  ContactUsContentInterface,
  AboutUsContentInterface,
  PageTitleAndImage,
  HeadingsInterface,
  PricingList,
  NavbarContent,
  FooterStaticContent,
  FooterSectionHeading,
  Fees,
} from '../../interfaces/'

const initialState: contentState = {
  HomeHeadings: {},
  HomeServices: [],
  HomeBanner: [],
  HomeSmartHeadings: [],
  ServicesPage: [],
  LogisiticsPage: [],
  TempDev: [],
  BookingsPage: [],
  Policy: {},
  AboutUs: {},
  ContactUs: {},
  CheckoutSteps: {},
  DriveWithMe: [],
  PageTitleAndImage: [],
  HeadingsAndListing: [],
  AboutUsNewData: [],
  BlogNewData: [],
  PricingData: [],
  OurTeam: [],
  OurLocations: [],
  WorkingWithkenan: [],
  Requirements: [],
  NavbarCOntent: null,
  FooterStaticContent: null,
  FooterSectionHeading: null,
  Fees: null,
}

const ContentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setFees: (state, action: PayloadAction<Fees>) => {
      state.Fees = action.payload
    },
    setFooterSectionHeading: (
      state,
      action: PayloadAction<FooterSectionHeading[]>,
    ) => {
      state.FooterSectionHeading = action.payload
    },
    setFooterStaticContent: (
      state,
      action: PayloadAction<FooterStaticContent>,
    ) => {
      state.FooterStaticContent = action.payload
    },
    setNavbarCOntent: (state, action: PayloadAction<NavbarContent>) => {
      state.NavbarCOntent = action.payload
    },
    setRequirements: (state, action: PayloadAction<ContentInterface[]>) => {
      state.Requirements = action.payload
    },
    setOurTeam: (state, action: PayloadAction<ContentInterface[]>) => {
      state.OurTeam = action.payload
    },
    setOurLocations: (state, action: PayloadAction<ContentInterface[]>) => {
      state.OurLocations = action.payload
    },
    setWorkingWithkenan: (state, action: PayloadAction<ContentInterface[]>) => {
      state.WorkingWithkenan = action.payload
    },
    setPricingData: (state, action: PayloadAction<PricingList[]>) => {
      state.PricingData = action.payload
    },
    setBlogNewData: (state, action: PayloadAction<ContentInterface[]>) => {
      state.BlogNewData = action.payload
    },
    setAboutUsNewData: (state, action: PayloadAction<ContentInterface[]>) => {
      state.AboutUsNewData = action.payload
    },
    setPageTitleAndImage: (
      state,
      action: PayloadAction<PageTitleAndImage[]>,
    ) => {
      state.PageTitleAndImage = action.payload
    },
    setHomeHeadings: (state, action: PayloadAction<ContentInterface>) => {
      state.HomeHeadings = action.payload
    },
    setHomeServices: (state, action: PayloadAction<ContentInterface[]>) => {
      state.HomeServices = action.payload
    },
    setHomeSmartHeadings: (
      state,
      action: PayloadAction<ContentInterface[]>,
    ) => {
      state.HomeSmartHeadings = action.payload
    },
    setHomeBanner: (state, action: PayloadAction<ContentInterface[]>) => {
      state.HomeBanner = action.payload
    },
    setServicesPage: (state, action: PayloadAction<ContentInterface[]>) => {
      state.ServicesPage = action.payload
    },
    setLogisiticsPage: (state, action: PayloadAction<ContentInterface[]>) => {
      state.LogisiticsPage = action.payload
    },
    setTempDev: (state, action: PayloadAction<ContentInterface[]>) => {
      state.TempDev = action.payload
    },
    setBookingsPage: (state, action: PayloadAction<ContentInterface[]>) => {
      state.BookingsPage = action.payload
    },

    setPolicy: (state, action: PayloadAction<PolicyInterface>) => {
      state.Policy = action.payload
    },
    setContactUs: (state, action: PayloadAction<ContactUsContentInterface>) => {
      state.ContactUs = action.payload
    },
    setAboutUs: (state, action: PayloadAction<AboutUsContentInterface>) => {
      state.AboutUs = action.payload
    },
    setCheckOutSteps: (state, action: PayloadAction<any>) => {
      state.CheckoutSteps = action.payload
    },
    setDriveWithme: (state, action: PayloadAction<ContentInterface[]>) => {
      state.DriveWithMe = action.payload
    },
    setHeadingsAndListing: (
      state,
      action: PayloadAction<HeadingsInterface[]>,
    ) => {
      state.HeadingsAndListing = action.payload
    },
  },
})

export const {
  setFees,
  setRequirements,
  setOurTeam,
  setOurLocations,
  setWorkingWithkenan,
  setPricingData,
  setBlogNewData,
  setAboutUsNewData,
  setBookingsPage,
  setHeadingsAndListing,
  setPageTitleAndImage,
  setDriveWithme,
  setHomeHeadings,
  setCheckOutSteps,
  setHomeSmartHeadings,
  setHomeServices,
  setServicesPage,
  setLogisiticsPage,
  setHomeBanner,
  setPolicy,
  setContactUs,
  setAboutUs,
  setNavbarCOntent,
  setFooterSectionHeading,
  setFooterStaticContent,
  setTempDev,
} = ContentSlice.actions

export default ContentSlice.reducer
